import React from 'react';
import { Box, Paper, Typography, IconButton, Stack } from '@mui/material';
import { HelpOutline, AccessTime } from '@mui/icons-material';
import HelpModal from './Help';

const GameTicket = ({ minute, ticketId = "TKT-2024-12345", timeLeft = "05:00" }) => {
  
  const [minutes, seconds] = timeLeft.split(":");
  const [isHelpOpen, setIsHelpOpen] = React.useState(false);
  const handleOpenHelp = () => setIsHelpOpen(true);
  const handleCloseHelp = () => setIsHelpOpen(false);
  
  return (
    <Paper
      elevation={0}
      style={{width: '100%', marginBottom: 15}}
      sx={{
        background: 'linear-gradient(135deg, #2196f3 0%, #1565c0 100%)',
        borderRadius: '16px',
        position: 'relative',
        overflow: 'hidden',
        '&::before, &::after': {
          content: '""',
          position: 'absolute',
          width: '25px',
          height: '25px',
          backgroundColor: '#f6f6f6',
          borderRadius: '50%',
          top: '50%',
          transform: 'translateY(-50%)',
        },
        '&::before': { left: -12 },
        '&::after': { right: -12 }
      }}
    >
      <Box sx={{ 
        p: 3,
        borderBottom: '2px dashed rgba(255,255,255,0.3)'
      }}>
        <Stack 
          direction="row" 
          justifyContent="space-between" 
          alignItems="center"
          sx={{ mb: 2 }}
        >
          
          <HelpOutline onClick={handleOpenHelp} style={{color: '#fff'}} fontSize="small" />

          <Box 
            sx={{
              bgcolor: 'rgba(255,255,255,0.15)',
              px: 2,
              py: 0.5,
              borderRadius: '12px'
            }}
          >
            <Typography variant="body2" color="white">
              {minute}
            </Typography>
          </Box>
        </Stack>
        
        <div style={{textAlign: 'center'}}>
          <Typography variant="h5" style={{color: 'rgba(255,255,255,0.7)'}}>
            Time Remaining
          </Typography>
        </div>

        <Stack style={{justifyContent: 'center'}} direction="row" alignItems="center" spacing={0.5}>
          
          {[...minutes].map((digit, i) => (
            <Box
              key={`min-${i}`}
              sx={{
                bgcolor: 'white',
                width: 32,
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1
              }}
            >
              <Typography variant="h5" fontWeight="bold" color="black">
                {digit}
              </Typography>
            </Box>
          ))}
          <Typography variant="h5" color="white" fontWeight="bold" sx={{ mx: 0.5 }}>
            :
          </Typography>

          {[...seconds].map((digit, i) => (
            <Box
              key={`sec-${i}`}
              sx={{
                bgcolor: 'white',
                width: 32,
                height: 60,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1
              }}
            >
              <Typography variant="h5" fontWeight="bold" color="black">
                {digit}
              </Typography>
            </Box>
          ))}

        </Stack>
      </Box>



      <Box sx={{ 
        bgcolor: 'rgba(255,255,255,0.1)',
        p: 2,
        textAlign: 'center'
      }}>
        <Typography 
          variant="subtitle2" 
          sx={{ color: 'rgba(255,255,255,0.7)' }}
        >
          {ticketId}
        </Typography>
      </Box>


      <HelpModal 
        open={isHelpOpen}
        onClose={handleCloseHelp}
      />


    </Paper>
  );
};

export default GameTicket;
import React, {useState, useEffect, useRef} from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Stack,
  LinearProgress,
  Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Footer from "./layouts/Footer";
import Navbar from "./layouts/Navbar";
import {Link} from "react-router-dom";
import AutoNotification from "./layouts/AutoNotification";
import Section from "./layouts/Section";
import CustomAgo from "./customComponents/CustomAgo";
import {WebLink, SiteWide, Images} from "./customComponents";
import {mainStyle, mainColor, header, otherColors} from '../utility/styles';
import Modal from 'react-modal';
import { useSelector, useDispatch } from 'react-redux';
import {tradeActions} from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect, allTradeSelect } from '../redux/selectors/index';
import Helper1, {niceDate, notify, styles, money, customNormalModalStyles, balanceSettings, currencySliderArray} from '../utility/Helper1';
import { useSnackbar } from 'snackfy';
import Countdown from 'react-countdown';
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { Dropdown } from 'semantic-ui-react'
import ModalCustomHead from "./customComponents/ModalCustomHead";
import {Menu, MenuItem, MenuButton} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import {RiMore2Fill, RiAddBoxFill, RiCloseLine, RiEye2Line, RiIndeterminateCircleFill, RiExchangeLine, RiBankFill, RiHandCoinFill, RiFundsFill, RiPieChartFill} from "react-icons/ri";

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)'
  }
}));

function Arbitrage() {
    const chooseCurrency = useRef();
    const site_settings = useSelector(allRemoteSettingsSelect);
    const [loading, setloading] = useState(false);
    const [tradeDataToShow, setTradeDataToShow] = useState([]);
    const [loadingText, setLoadingText] = useState('');
    const [currentSentTradeData, setCurrentSentTradeData] = useState({});
    const tradesData = useSelector(allTradeSelect);
    const finance = useSelector(homeScreenStatSelect);
    const [selectedPlan, setSelectedPlan] = useState(site_settings?.plans?.[0]?.childs?.[0]);
    const [openModal, setOpenModal] = useState(false);
    const [openTradeModal, setOpenTradeModal] = useState(false);
    const [tradeCapital, setTradeCapital] = useState('');
    const [selectedImage, setSelectedImage] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');

    const dispatch = useDispatch();
    const type = finance?.[0]?.type;
    const allUnprocessed = tradesData?.childs?.filter((item) => item.isProcessed == 0);
    const today = tradesData?.childs?.filter((item) => item.today == 1);
    const active = tradesData?.data?.filter((item) => item.active == 1);
    const inActive = tradesData?.data?.filter((item) => item.active == 0);
    const allUnprocessedSum = allUnprocessed?.reduce((a, b) => Number(a) + Number(b.profit), 0);
    const allProfits = today?.reduce((a, b) => Number(a) + Number(b?.profit), 0);
    const allCapitals = today?.reduce((a, b) => Number(a) + Number(b?.capital), 0);

    // Preserve all existing useEffects and methods
    useEffect(() => {
        loadAllTrade();
        const reloadEverytime = setInterval(() => {
            loadAllTrade();
        }, 15000);
        return () => clearInterval(reloadEverytime);
    }, []);

    useEffect(() => {
        referralData();
    }, []);

    const loadAllTrade = () => {
        dispatch(tradeActions.onGETTheTrades("trade/all", response => {}));
    };

    const intermittentTrade = () => {
        if(allUnprocessed?.length == 0){ 
            notify('No order currently available to process', 0); 
            return; 
        }
        setloading(true);
        setLoadingText('Processing...');
        dispatch(tradeActions.onGETTheTrades("trade/process/manual", response => {
            setloading(false);
            notify(response.msg, response.code);
        }));
    };

    const terminateContract = (id) => {
        setloading(true);
        setLoadingText('Terminating contract...');
        dispatch(tradeActions.onGETTheTrades(`trade/close/${id}`, response => {
            setloading(false);
            notify(response.msg, response.code);
        }));
    };

    const startTradeModal = (currencyObj) => {
        setSelectedCurrency(currencyObj);
        setOpenTradeModal(true);
    };

    const tradeNow = (thePlanId) => {
        setloading(true);
        setLoadingText('Trading...');
        dispatch(tradeActions.onPOSTTheTrades(
            {currency: thePlanId, amount: tradeCapital}, 
            "trade/new", 
            response => {
                setloading(false);
                notify(response.msg, response.code);
                if(response.code == 1){
                    setloading(false);
                    setOpenTradeModal(false);
                    referralData();
                }
            }
        ));
    };

    const referralData = () => {
        const tradeDataLength = (tradeDataToShow?.length ?? 0);
        const tradesDataCloned = [...tradesData.childs];
        const newLength = tradesDataCloned?.slice(0, (tradeDataLength + 2));
        setTradeDataToShow((e) => [...newLength]);
    };

    return (
        <SiteWide title="Go global">
            <Section overlayText={loadingText} loading={loading} title="Go global">
                <Box sx={{ p: 3, mb: 5 }}>
                    {loading && <LinearProgress sx={{ mb: 2 }} />}
                    
                    <Grid container spacing={3}>
                        {finance.map((finItem) => (
                            <Grid item xs={12} md={12} key={finItem.type}>
                                <StyledCard>
                                    <CardContent>
                                        <Stack spacing={3}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={4}>
                                                    <Typography color="textSecondary">Total Trades</Typography>
                                                    <Typography variant="h6">{money(finItem?.balance ?? 0, finItem.type)}</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Typography color="textSecondary">Profits</Typography>
                                                    <Typography variant="h6">{money(finItem?.profits ?? 0, finItem.type)}</Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <Button
                                                        variant="contained"
                                                        startIcon={<RiAddBoxFill />}
                                                        onClick={() => startTradeModal(finItem)}
                                                        fullWidth
                                                    >
                                                        New {finItem.abbr}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Stack>
                                    </CardContent>
                                </StyledCard>
                            </Grid>
                        ))}
                    </Grid>

                    <Box sx={{ mt: 4, px: 0 }}>
                        <Typography variant="h5" gutterBottom sx={{ mb: 3 }}>
                            Transaction History
                        </Typography>
                        
                        <Stack spacing={2}>
                            {tradeDataToShow?.map((trade, index) => (
                            <StyledCard key={index} elevation={0} sx={{ 
                                border: '1px solid',
                                borderColor: 'divider',
                                '&:hover': {
                                borderColor: 'primary.main',
                                }
                            }}>
                                <CardContent>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <Box>
                                        <Typography variant="h6" color="primary">
                                            {money(trade.capital, trade.type)}
                                        </Typography>
                                        <Typography variant="caption" color="text.secondary">
                                            ID: {trade.txn_id}
                                        </Typography>
                                        </Box>
                                        <Box>
                                        <Chip 
                                            label={trade.active ? "Active" : "Completed"}
                                            color={trade.active ? "success" : "default"}
                                            size="small"
                                            sx={{ mr: 1 }}
                                        />

                                        {trade.useTermination == 1 && (
                                            <IconButton size="small">
                                            <Menu menuButton={<MenuButton style={{backgroundColor: 'transparent', border: 0}}><RiMore2Fill /></MenuButton>}>
                                                <MenuItem 
                                                onClick={() => terminateContract(trade.id)}
                                                disabled={trade.terminated == 1 || trade.active == 0}
                                                >
                                                Terminate contract
                                                </MenuItem>
                                            </Menu>
                                            </IconButton>
                                        )}
                                        </Box>
                                    </Stack>
                                    </Grid>

                                    {trade?.active == 1 && (
                                    <Grid item xs={12}>
                                        <Box sx={{ 
                                        bgcolor: 'action.hover',
                                        p: 1.5,
                                        borderRadius: 1,
                                        display: 'flex',
                                        alignItems: 'center'
                                        }}>
                                        <Typography variant="body2" color="text.secondary" sx={{ mr: 1 }}>
                                            Time remaining:
                                        </Typography>
                                        <Countdown
                                            date={new Date(trade?.timeToMaturedForCalculation)}
                                            renderer={({ hours, minutes, seconds }) => (
                                            <Typography variant="body2" color="primary.main" fontWeight="medium">
                                                {`${hours}:${minutes}:${seconds}`}
                                            </Typography>
                                            )}
                                        />
                                        </Box>
                                    </Grid>
                                    )}

                                    <Grid item xs={12}>
                                    <Box sx={{ 
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(3, 1fr)',
                                        gap: 2,
                                        mt: 1
                                    }}>
                                        <Box>
                                        <Typography variant="body2" color="text.secondary">Date</Typography>
                                        <Typography variant="body2">{niceDate(trade?.timeCreated)}</Typography>
                                        </Box>
                                        <Box>
                                        <Typography variant="body2" color="text.secondary">Profits</Typography>
                                        <Typography variant="body2" color="success.main">
                                            {money(trade.profit, trade.type)}
                                        </Typography>
                                        </Box>
                                        <Box>
                                        <Typography variant="body2" color="text.secondary">Next Earning</Typography>
                                        <Typography variant="body2">{niceDate(trade?.timeToNExtMaturity)}</Typography>
                                        </Box>
                                    </Box>
                                    </Grid>
                                </Grid>
                                </CardContent>
                            </StyledCard>
                            ))}
                        </Stack>

                        {(tradeDataToShow?.length ?? 0) < tradesData?.totalPages && (
                            <Button 
                            variant="outlined"
                            onClick={referralData}
                            sx={{ 
                                mt: 3,
                                display: 'block',
                                mx: 'auto',
                                minWidth: 200
                            }}
                            >
                            Load More Transactions
                            </Button>
                        )}
                    </Box>

                    
                    <Dialog
  open={openTradeModal}
  onClose={() => setOpenTradeModal(false)}
  maxWidth="sm"
  fullWidth
  PaperProps={{
    sx: {
      borderRadius: 2,
      backgroundImage: 'linear-gradient(to right bottom, #1a237e, #0d47a1)',
      color: 'white'
    }
  }}
>
  <DialogTitle sx={{ 
    display: 'flex', 
    justifyContent: 'space-between',
    alignItems: 'center',
    pb: 3
  }}>
    <Box>
      <Typography variant="h5" sx={{ color: 'white', fontWeight: 600 }}>
        New Investment
      </Typography>
      <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)', mt: 0.5 }}>
        Start a new trading position
      </Typography>
    </Box>
    <IconButton onClick={() => setOpenTradeModal(false)} sx={{ color: 'white' }}>
      <RiCloseLine />
    </IconButton>
  </DialogTitle>

  <DialogContent sx={{ pt: 2 }}>
    <Box sx={{ 
      bgcolor: 'rgba(255,255,255,0.1)',
      borderRadius: 2,
      p: 2,
      mb: 3
    }}>
      <TextField
        value={tradeCapital}
        onChange={(e) => setTradeCapital(e.target.value)}
        placeholder="Enter amount"
        variant="outlined"
        fullWidth
        InputProps={{
          startAdornment: (
            <Typography variant="h6" sx={{ mr: 1, color: 'white' }}>
              {selectedCurrency?.symbol}
            </Typography>
          ),
          sx: {
            color: 'white',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(255,255,255,0.3)'
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(255,255,255,0.5)'
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: 'white'
            }
          }
        }}
      />
    </Box>

    <Typography variant="subtitle2" sx={{ color: 'rgba(255,255,255,0.7)', mb: 2 }}>
      Select Investment Plan
    </Typography>
{console.log('selectedCurrency', selectedCurrency)}
    <Grid container spacing={2}>
      {site_settings?.plans?.filter(item => 
        item.mother.type === selectedCurrency?.type)[0]?.childs.map((plan) => (
        <Grid item xs={12} sm={6} key={plan.type}>

          <Card 
            onClick={() => window.confirm(`Are you sure you want to proceed with this trade? ${money(tradeCapital, selectedCurrency?.type)} will be debited from your wallet. Click OK to continue.`) && tradeNow(plan.type)}
            sx={{
              bgcolor: 'rgba(255,255,255,0.1)',
              borderRadius: 2,
              cursor: 'pointer',
              transition: 'all 0.2s',
              '&:hover': {
                bgcolor: 'rgba(255,255,255,0.2)',
                transform: 'translateY(-4px)'
              }
            }}
          >
            <CardContent>
              <Stack spacing={1}>
                <Typography variant="h6" sx={{ color: 'white' }}>
                  {plan.name}
                </Typography>
                <Typography variant="body2" sx={{ color: 'rgba(255,255,255,0.7)' }}>
                  Expected Return: {plan?.roi}%
                </Typography>
                <Box sx={{ mt: 1 }}>
                  <Chip 
                    label="Select Plan"
                    size="small"
                    sx={{
                      bgcolor: 'rgba(255,255,255,0.2)',
                      color: 'white',
                      '&:hover': { bgcolor: 'rgba(255,255,255,0.3)' }
                    }}
                  />
                </Box>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  </DialogContent>
</Dialog>


                </Box>
            </Section>
        </SiteWide>
    );
}

export default Arbitrage;
import { all,call, put, takeEvery } from "redux-saga/effects";
import * as actionTypes from "../actions/actionTypes";

import APIRequests from '../../utility/APIRequests';




function* getAllGames(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall, action.param);

    if (response.code == 1) {
      response.data.user.token = response.access_token;
      yield put({ type: actionTypes.GAMES_SAVE_ALL, data: response.data.games });

    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}


function* getUserGames(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall);

    if (response.code == 1) {
      yield put({ type: actionTypes.GAMES_SAVE_USERS, data: response.data.userGames });
    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}



function* getGameHistory(action) {
  try {
    const response = yield (new APIRequests).runGet(action.routeToCall);

    if (response.code == 1) {
      yield put({ type: actionTypes.GAMES_SAVE_HISTORY, data: response.data.gameHistory });
    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}




function* onNewGame(action) {
  try {
    const response = yield (new APIRequests).runPost(action.routeToCall, action.param);

    if (response.code == 1) {
      
      yield put({ type: actionTypes.GAMES_SAVE_USERS, data: response.data.userGames });
      // response.data.user.token = response.access_token;

      // yield put({ type: actionTypes.LOGIN_SUCCESS, user: response.data.user });
      yield put({ type: actionTypes.HOME_SCREEN_STAT, homescreenstat: response.data.finance });
      
    }

    return action.callback?.(response);
  } catch (error) {

    return action.callback?.(error.response ?? error.message);
  }
}


function* watchOnGetAllGames() {
  yield takeEvery(actionTypes.GAMES_LOAD_ALL, getAllGames);
}


function* watchOnGetUserGames() {
  yield takeEvery(actionTypes.GAMES_LOAD_USERS, getUserGames);
}


function* watchOnNewGame() {
  yield takeEvery(actionTypes.GAMES_BET_NEW, onNewGame);
}


function* watchOnGetGameHistory() {
  yield takeEvery(actionTypes.GAMES_LOAD_HISTORY, getGameHistory);
}



export default function* tradeSagas() {
  yield all([watchOnGetAllGames(), watchOnGetUserGames(), watchOnNewGame(), watchOnGetGameHistory()]);
}

import React from "react";
import {Link} from "react-router-dom";


export default function WebLink(props){

	const {to, children, ...rest} = props;

	const MainLink = to?Link:'span'
	return <MainLink to={to} {...rest} >{children}</MainLink>

}


import React, { useState } from 'react';
import {
  Box,
  Card,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Paper,
  Alert,
  Snackbar,
  IconButton,
  InputAdornment,
  Tooltip,
} from '@mui/material';
import { ContentCopy, QrCode2, Info } from '@mui/icons-material';

const cryptoAssets = [
  { id: 'btc', name: 'Bitcoin', symbol: 'BTC', minDeposit: 0.001 },
  { id: 'eth', name: 'Ethereum', symbol: 'ETH', minDeposit: 0.01 },
  { id: 'usdt', name: 'Tether', symbol: 'USDT', minDeposit: 10 },
  { id: 'sol', name: 'Solana', symbol: 'SOL', minDeposit: 0.1 },
];

const DepositScreen = () => {
  const [selectedAsset, setSelectedAsset] = useState(cryptoAssets[0]);
  const [showCopiedAlert, setShowCopiedAlert] = useState(false);
  
  // Mock wallet address - in real app, this would be generated per user/asset
  const walletAddress = '0x742d35Cc6634C0532925a3b844Bc454e4438f44e';

  const handleAssetChange = (event) => {
    const asset = cryptoAssets.find(a => a.id === event.target.value);
    setSelectedAsset(asset);
  };

  const handleCopyAddress = () => {
    navigator.clipboard.writeText(walletAddress);
    setShowCopiedAlert(true);
  };

  return (
    <Box sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 4 }}>
        Deposit Funds
      </Typography>

      <Card sx={{ p: 3, mb: 3 }}>
        <FormControl fullWidth sx={{ mb: 3 }}>
          <InputLabel>Select Asset</InputLabel>
          <Select
            value={selectedAsset.id}
            label="Select Asset"
            onChange={handleAssetChange}
          >
            {cryptoAssets.map((asset) => (
              <MenuItem key={asset.id} value={asset.id}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  {/* Placeholder for crypto icon */}
                  <Box
                    sx={{
                      width: 24,
                      height: 24,
                      borderRadius: '50%',
                      bgcolor: 'grey.200',
                      mr: 1
                    }}
                  />
                  {asset.name} ({asset.symbol})
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Paper variant="outlined" sx={{ p: 2, mb: 3 }}>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Deposit Address ({selectedAsset.symbol})
          </Typography>
          
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <TextField
              fullWidth
              value={walletAddress}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip title="Copy address">
                      <IconButton onClick={handleCopyAddress}>
                        <ContentCopy />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Show QR Code">
                      <IconButton>
                        <QrCode2 />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Alert severity="warning" icon={<Info />}>
            Minimum deposit: {selectedAsset.minDeposit} {selectedAsset.symbol}
          </Alert>
        </Paper>

        <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
          • Only send {selectedAsset.symbol} to this address<br />
          • Deposits will be credited after {selectedAsset.symbol === 'BTC' ? '2' : '15'} network confirmations<br />
          • Processing time may vary depending on network congestion
        </Typography>

        <Button 
          variant="contained" 
          color="primary" 
          fullWidth
          onClick={() => window.history.back()}
        >
          Done
        </Button>
      </Card>

      <Snackbar
        open={showCopiedAlert}
        autoHideDuration={2000}
        onClose={() => setShowCopiedAlert(false)}
        message="Address copied to clipboard"
      />
    </Box>
  );
};

export default DepositScreen;
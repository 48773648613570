import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Alert,
  Select,
  MenuItem,
  FormControlLabel,
  Checkbox,
  InputLabel,
  FormControl,
} from '@mui/material';
import { Visibility, VisibilityOff, PersonAddOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { authActions } from '../redux/actions';
import { staticCountries } from '../utility/countries';
import Captcha from "react-numeric-captcha";

const Signup = () => {
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    country: '',
    phone: '',
    referral: localStorage?.getItem('ref') || '',
  });
  
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [agree, setAgree] = useState(false);
  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [phoneCode, setPhoneCode] = useState('');
  
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  // Get referral from URL params
  React.useEffect(() => {
    const params = new URLSearchParams(location.search);
    const ref = params.get('ref');
    if (ref) {
      localStorage.setItem('ref', ref);
      setFormData(prev => ({ ...prev, referral: ref }));
    }
  }, [location]);

  const handleChange = (prop) => (event) => {
    if (prop === 'country') {
      const country = staticCountries.find(c => c.id === event.target.value);
      setPhoneCode(country?.phonecode || '');
    }
    setFormData({ ...formData, [prop]: event.target.value });
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (!captchaSuccess) {
      setError('Please complete the captcha verification');
      return;
    }

    if (!agree) {
      setError('Please accept the terms and conditions');
      return;
    }

    setLoading(true);
    
    dispatch(authActions.onSignUp(
      "account/register",
      {
        ...formData,
        phone: `${phoneCode}${formData.phone}`
      },
      response => {
        setLoading(false);
        if (response.code !== 0) {
          localStorage.removeItem('ref');
          history.replace('/personal-information');
        } else {
          setError(response.msg);
        }
      }
    ));
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
        background: 'linear-gradient(135deg, #202630 0%, #171e26 100%)',
        position: 'fixed',
        top: 0,
        left: 0,
        overflow: 'auto',
        padding: { xs: 2, sm: 4 }
      }}
    >
      <Card
        elevation={8}
        sx={{
          width: '100%',
          maxWidth: '500px',
          borderRadius: 2,
          backdropFilter: 'blur(20px)',
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          position: 'relative',
          my: 'auto'
        }}
      >
        <CardContent sx={{ p: { xs: 3, sm: 4 } }}>
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography
              variant="h4"
              component="h1"
              sx={{ 
                fontWeight: 'bold',
                color: '#1a237e',
                mb: 1
              }}
            >
              Create Account
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Fill in your details to get started
            </Typography>
          </Box>

          {error && (
            <Alert 
              severity="error" 
              sx={{ mb: 3 }}
            >
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              value={formData.username}
              onChange={handleChange('username')}
              sx={{ mb: 3 }}
            />

            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              value={formData.password}
              onChange={handleChange('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3 }}
            />

            <FormControl fullWidth sx={{ mb: 3 }}>
              <InputLabel>Country</InputLabel>
              <Select
                value={formData.country}
                label="Country"
                onChange={handleChange('country')}
              >
                {staticCountries.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.nicename}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              fullWidth
              label="Phone Number"
              variant="outlined"
              value={formData.phone}
              onChange={handleChange('phone')}
              InputProps={{
                startAdornment: phoneCode && (
                  <InputAdornment position="start">
                    {phoneCode}
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 3 }}
            />

            <Box sx={{ mb: 3 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={agree}
                    onChange={(e) => setAgree(e.target.checked)}
                    color="primary"
                  />
                }
                label={
                  <Typography variant="body2">
                    I agree to the{' '}
                    <Button
                      color="primary"
                      onClick={() => history.push('/term')}
                      sx={{ p: 0, minWidth: 0, textTransform: 'none', textDecoration: 'underline' }}
                    >
                      terms and conditions
                    </Button>
                  </Typography>
                }
              />
            </Box>

            <Box sx={{ mb: 3 }}>
              <Captcha
                onChange={status => setCaptchaSuccess(status)}
                placeholder="Enter captcha"
              />
            </Box>

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{
                py: 1.5,
                bgcolor: '#1a237e',
                '&:hover': {
                  bgcolor: '#283593'
                },
                mb: 3
              }}
              startIcon={<PersonAddOutlined />}
            >
              {loading ? 'Creating Account...' : 'Sign Up'}
            </Button>
          </form>

          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" color="textSecondary">
              Already have an account?{' '}
              <Button
                color="primary"
                onClick={() => history.push('/signin')}
                sx={{ textTransform: 'none' }}
              >
                Sign in
              </Button>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Signup;
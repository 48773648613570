import React, {useEffect} from "react";
import LoadingOverlay from 'react-loading-overlay';
// import BounceLoader from 'react-spinners/BounceLoader'
import {mainStyle, mainColor, header, footer, otherColors} from '../../utility/styles';

import { allRemoteSettingsSelect } from '../../redux/selectors/index';
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from '../../redux/actions';
import Notify from './Notify';
import Navbar from "../layouts/Navbar";

import { Helmet, HelmetProvider } from 'react-helmet-async';
import {Images} from "./index";

import { 
    Box,
    Button,
    Typography,
    Container,
    Paper,
  } from '@mui/material';
import Loading from "../layouts/Loading";


function SiteWide(props) {

    const {title, description, children, ...rest} = props
    const site_settings = useSelector(allRemoteSettingsSelect);


    const titleToUse = title ?? site_settings?.site_settings?.title ?? 'welcome';
    const descToUse = description ?? site_settings?.site_settings?.description ?? 'You are here';
    const siteName = site_settings?.site_settings?.sitename ?? 'dotcom';
    const url = site_settings?.site_settings?.site_url ?? 'dotcom';
    const logo = site_settings?.site_settings?.logo ?? '';
    const favicon = site_settings?.site_settings?.favicon ?? '';

    const bg = require('../../images/auth.jpg');

    return (
        <HelmetProvider>
            <Container maxWidth="sm" style={{padding: 0}} sx={{ py: 0, px: 0, bgcolor: '#1a237e' }}>
            
                <Loading loading={site_settings?.masterIsLoading ?? true} >
                    <Helmet>

                        {/* <meta name="theme-color" content={header} /> */}
                        <meta name="theme-color" content={'#eeeeee'} />

                        <title>{titleToUse}</title>
                        <link rel="icon" type="image/x-icon" href={favicon} />
                        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
                        <meta name="description" content={descToUse} />

                        <meta property="og:title" content={titleToUse} />
                        <meta property="twitter:title" content={titleToUse} />
            
                        <meta property="og:site_name" content={siteName} />
                        <meta property="twitter:site" content={siteName} />

                        <meta property="og:description" content={descToUse} />
                        <meta property="twitter:description" content={descToUse} />
            
                        <meta property="og:type" content="website" />

                        <meta property="og:url" content={url} />

                        <meta property="og:image" content={logo} />
                        <meta property="twitter:image" content={logo} />

                        <meta name="twitter:card" content="summary_large_image" />

                    </Helmet>

                    <div {...rest} style={{backgroundColor: '#f6f6f6'}} >
                        <Notify/>
                        <div id={!props.hideMainDiv?'main':''}>
                            {children}
                        </div>
                    </div>
                </Loading>

            </Container>
        </HelmetProvider>
    );
}

export default SiteWide;
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  Container,
  CircularProgress,
  Link
} from '@mui/material';
import { LockReset } from '@mui/icons-material';
import Countdown from 'react-countdown';
import { authActions } from '../../redux/actions';
import { 
  userSelect, 
  allRemoteSettingsSelect, 
  homeScreenStatSelect 
} from '../../redux/selectors';
import { notify } from '../../utility/Helper1';

import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";

const SecurityPINManager = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userSelect);
  const siteSettings = useSelector(allRemoteSettingsSelect);
  const finance = useSelector(homeScreenStatSelect);

  const [withdrawalPin, setWithdrawalPin] = useState('');
  const [emailOTP, setEmailOTP] = useState('');
  const [loading, setLoading] = useState(false);

  const handlePinChange = async (e) => {
    e.preventDefault();
    setLoading(true);

    dispatch(authActions.onLogin(
      'account/withdrawal-pin/change',
      { emailOTP, withdrawalPin },
      (response) => {
        notify(response.msg, response.code);
        setLoading(false);
        if (response.code === 1) {
          setWithdrawalPin('');
        }
      }
    ));
  };

  const getNewEmailOTP = () => {
    dispatch(authActions.onLogin(
      'account/send-email-otp/withdrawal-pin',
      {},
      () => {}
    ));
  };

  useEffect(() => {
    getNewEmailOTP();
  }, []);

  const CountdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      return (
        <Link
          component="button"
          variant="body2"
          onClick={getNewEmailOTP}
          sx={{ cursor: 'pointer' }}
        >
          Request OTP
        </Link>
      );
    }
    return (
      <Typography variant="body2" color="text.secondary">
        Resend OTP after {minutes}:{seconds} seconds
      </Typography>
    );
  };

  return (<SiteWide>
        <Section overlayText={'Loading...'} loading={false} title={'Manage Security PIN'}>
          <Container maxWidth="sm">
            <Paper 
              elevation={0} 
              sx={{ 
                p: 4, 
                mt: 4,
                backgroundColor: theme => theme.palette.background.paper
              }}
            >

              <Box component="form" onSubmit={handlePinChange} sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  label="New Security PIN"
                  type="password"
                  value={withdrawalPin}
                  onChange={(e) => setWithdrawalPin(e.target.value)}
                  required
                  inputProps={{ maxLength: 4 }}
                  margin="normal"
                  variant="outlined"
                  autoComplete="new-password"
                />

                <TextField
                  fullWidth
                  label="Email OTP"
                  type="text"
                  value={emailOTP}
                  onChange={(e) => setEmailOTP(e.target.value)}
                  required
                  margin="normal"
                  variant="outlined"
                  placeholder="Enter OTP received in email"
                />

                <Box sx={{ mt: 2, mb: 2, textAlign: 'right' }}>
                  <Countdown
                    zeroPadTime={2}
                    key={new Date(userData?.withdrawalPinResetTime ?? 0)}
                    date={new Date(userData?.withdrawalPinResetTime ?? 0)}
                    renderer={CountdownRenderer}
                  />
                </Box>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  fullWidth
                  disabled={loading}
                  sx={{ 
                    height: 48,
                    position: 'relative',
                    mt: 2
                  }}
                >
                  {loading ? (
                    <CircularProgress 
                      size={24} 
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px'
                      }}
                    />
                  ) : 'Save Changes'}
                </Button>
              </Box>
            </Paper>
          </Container>
        </Section>
      </SiteWide>
  );
};

export default SecurityPINManager;
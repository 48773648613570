import React from 'react';
import { 
  Container, 
  Box, 
  Typography, 
  useMediaQuery, 
  useTheme,
  IconButton
} from '@mui/material';
import { ChevronLeft } from 'lucide-react';
import {useHistory } from 'react-router-dom';

const Header = ({ 
  title, 
  rightTopMenu,
  onBack,
  backgroundColor = '#eee', // Your primary color
  textColor = '#ffffff'
}) => {
  const theme = useTheme();
  const history = useHistory();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      history.goBack();
    }
  };

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        zIndex: theme.zIndex.appBar,
        width: '100%',
        padding: 0
      }}
    >
      <Container
        maxWidth="sm"
        disableGutters={true}
        sx={{
          padding: 0,
          borderRadius: 0,
          overflow: 'hidden',
        //   boxShadow: theme.shadows[3],
          transition: 'box-shadow 0.2s ease-in-out',
          '&:hover': {
            // boxShadow: theme.shadows[4]
          }
        }}
      >
        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: '16px', // px: 2, py: 2 equivalent
            backgroundColor: backgroundColor,
            color: textColor,
            height: '64px',
            transition: 'all 0.2s ease-in-out'
          }}
        >
          <IconButton
            onClick={handleBack}
            sx={{
              color: 'inherit',
              p: 1,
              '&:hover': {
                transform: 'scale(1.1)',
                bgcolor: 'rgba(255, 255, 255, 0.1)'
              },
              transition: 'all 0.2s ease-in-out'
            }}
          >
            <ChevronLeft style={{color: '#000'}} size={24} />
          </IconButton>

          <Typography 
            variant="h5" 
            sx={{ 
            fontWeight: 600,
            }}
        >
            {title}
        </Typography>

          <Box 
            sx={{
              minWidth: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            {rightTopMenu}
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Header;
import React, { useState, useEffect, useRef } from "react";
import Footer from "../layouts/Footer";
import { WebLink, SiteWide, Images } from "../customComponents";
import { mainStyle, mainColor, header, footer, otherColors } from '../../utility/styles';
import Slider from "react-slick";
import Colors from '../../utility/Colors';
import { homepage } from '../../utility/APIRequests';
import Section from "../layouts/Section";
import LoadingOverlay from 'react-loading-overlay';
import { useSelector, useDispatch } from 'react-redux';
import { exchangeSelect, allTradeSelect, allDepositSelect, userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../../redux/selectors/index';
import { authActions, withdrawalActions, depositActions, tradeActions } from '../../redux/actions';
import AutoNotification from "../layouts/AutoNotification";
import NewsTicker, { Directions } from 'react-advanced-news-ticker';
import Helper1, { notify, styles, money, FormatOnly, customNormalModalStyles, moneyStatus, sumFieldInArray } from '../../utility/Helper1';
import { useHistory } from "react-router-dom";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
    RiCheckboxIndeterminateLine, RiMore2Fill, RiCalculatorLine, RiShareLine, RiArrowDropRightLine, RiLogoutBoxLine, RiLogoutBoxRLine, RiVolumeUpFill, RiContactsBookUploadLine, RiNewspaperLine, RiIncreaseDecreaseLine, RiFundsFill, RiGiftFill, RiSwapBoxFill, RiMapPinRangeFill, RiTelegramFill, RiWhatsappFill, RiStockFill, RiRemoteControlFill, RiInstallFill, RiKeyboardFill, RiCheckboxLine, RiArrowLeftLine, RiShareForward2Fill, RiBattery2ChargeLine, RiBatteryShareFill, RiCastLine, RiDownload2Line, RiNotification3Line, RiArrowDownCircleFill, RiMailCheckFill, RiErrorWarningFill, RiDeleteBin2Fill, RiMoneyDollarCircleLine, RiFileCopy2Fill, RiGiftLine, RiSecurePaymentLine, RiRecordCircleFill, RiShieldCrossLine, RiUser4Fill, RiExternalLinkFill, RiContactsBookFill,
    RiWallet3Fill, RiCloseCircleFill, RiCalendarTodoFill, RiArrowGoBackLine, RiGasStationFill, RiBankCard2Line, RiShieldUserFill, RiTeamFill, RiTicket2Line, RiUserAddFill
} from "react-icons/ri";
import { FcUpload } from "react-icons/fc";
import CustomAgo from "../customComponents/CustomAgo";
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";
import Header from '../layouts/Header';
import Ticker from 'react-ticker';
import { PaystackButton, usePaystackPayment } from 'react-paystack';
import { useFlutterwave, closePaymentModal } from 'flutterwave-react-v3';
import MenuGrid from './Dashboard';
import BalanceSection from "./BalanceAndGame";
import HeadlineComponent from "./Headline";

// MUI Imports for ModernModal
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Typography, TextField, Button, IconButton, Box, Grid, Paper, Divider, Avatar, List, ListItem, ListItemText, ListItemIcon, InputAdornment, Chip,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from "@mui/icons-material/Delete"; // Corrected from TrashIcon

// import TrashIcon from '@mui/icons-material/Trash';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// Styled Components for ModernModal
const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialog-paper': {
        borderRadius: 16,
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        maxWidth: '90%',
        width: '500px',
    },
}));

const CurrencyOption = styled(Paper)(({ theme, selected }) => ({
    display: 'flex',
    padding: theme.spacing(2),
    borderRadius: 12,
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: selected ? theme.palette.primary.light : theme.palette.background.paper,
    border: `1px solid ${selected ? theme.palette.primary.main : theme.palette.divider}`,
    transition: 'all 0.2s ease-in-out',
    '&:hover': { transform: 'translateY(-2px)', boxShadow: theme.shadows[3] },
}));

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const CalculatorButton = styled(Button)(({ theme, operator }) => ({
    borderRadius: '50%',
    minWidth: '64px',
    height: '64px',
    margin: theme.spacing(0.5),
    fontSize: '1.5rem',
    backgroundColor: operator ? theme.palette.primary.main : theme.palette.background.paper,
    color: operator ? theme.palette.primary.contrastText : theme.palette.text.primary,
    '&:hover': { backgroundColor: operator ? theme.palette.primary.dark : theme.palette.divider },
}));

const CalculatorDisplay = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(3),
    textAlign: 'right',
    borderRadius: 12,
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#f5f5f5',
}));

const SubmitButton = ({ loading, onClick, type = "submit", endIcon }) => (
    <Button
        type={type}
        variant="contained"
        fullWidth
        size="large"
        disabled={loading}
        sx={{ borderRadius: 2, py: 1.5, textTransform: 'none', fontWeight: 500, boxShadow: 2 }}
        onClick={onClick}
        endIcon={endIcon}
    >
        Submit
    </Button>
);

const modalTitle = [
    'Withdraw',
    'Recharge',
    'Transfer',
    'Trade',
    'Calculator',
];

const menuIcons = [
    { images: 'dash_5.png', info: 'FAQ', linked: 'faq' },
    { images: 'dash_6.png', info: 'About', linked: 'about' },
];

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: true,
    responsive: [
        { breakpoint: 1024, settings: { slidesToShow: 2, slidesToScroll: 2, infinite: true, dots: true } },
        { breakpoint: 900, settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 1 } },
        { breakpoint: 600, settings: { slidesToShow: 1, slidesToScroll: 1, initialSlide: 1 } },
        { breakpoint: 480, settings: { slidesToShow: 1, slidesToScroll: 1 } },
    ],
};

const balanceSettings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    autoplay: false,
    responsive: [{ breakpoint: 600, settings: { slidesToShow: 2, slidesToScroll: 2, initialSlide: 1 } }],
};

function Home() {
    const hiddenFileInput = React.useRef(null);
    const uploadPayment = React.useRef(null);

    const [uploadingAvatar, setUploadingAvatar] = useState(false);
    const history = useHistory();
    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const exchangeStoreData = useSelector(exchangeSelect);
    const tradeStoreData = useSelector(allTradeSelect);
    const allDepositData = useSelector(allDepositSelect);

    const [openModal, setOpenModal] = useState(false);
    const [whichModal, setWhichModal] = useState(1);
    const [loadingStat, setLoadingStat] = useState(true);

    const [withdrawAmount, setWithdrawAmount] = useState('');
    const [withdrawalPin, setWithdrawalPin] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [whichWithdrawal, setWhichWithdrawal] = useState(0);

    const [selectedMotherCurrencyForCalculator, setSelectedMotherCurrencyForCalculator] = useState(site_settings?.plans?.[0]?.mother);
    const [selectedPlanCurrencyForCalculator, setSelectedPlanCurrencyForCalculator] = useState(site_settings?.plans?.[0]?.childs);
    const [calculatorInput, setCalculatorInput] = useState(0);
    const [calculatorResult, setCalculatorResult] = useState(0);

    const [depositAmount, setDepositAmount] = useState('');
    const [selectedCurrency, setSelectedCurrency] = useState('');
    const [selectedCurrencyObj, setSelectedCurrencyObj] = useState('');

    const [depositorName, setDepositorName] = useState('');
    const [depositorBank, setDepositorBank] = useState('');
    const [depositorAmountPaid, setDepositorAmountPaid] = useState('');
    const [depositorFile, setDepositorFile] = useState('assets/img/image.png');

    const [receiverEmail, setReceiverEmail] = useState('');
    const [sendingAmount, setSendingAmount] = useState('');

    const [loading, setLoading] = useState(false);
    const [loadingText, setLoadingText] = useState('');

    const [tradeAmount, setTradeAmount] = useState('');
    const [selectedPlan, setSelectedPlan] = useState(site_settings?.plans?.[0]?.childs?.[0]);
    const salaryData = site_settings?.salary_settings;

    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const menuOpen = Boolean(anchorEl);

    const handleMenuClick = (event) => setAnchorEl(event.currentTarget);
    const handleMenuClose = () => setAnchorEl(null);

    const doLogOutNowNow = () => dispatch(authActions.onLogout());
    const shareMy = () => { };

    const referralCode = () => {
        if ((userData?.emailVerification ?? 0) === 0) history.push('/signin');
    };

    const copyRefLink = () => {
        if ((userData?.emailVerification ?? 0) === 0) {
            notify('Please log in', 0);
            return;
        }
        notify('Referral Link Copied', 1);
    };

    const openWithdrawal = () => {
        if ((userData?.emailVerification ?? 0) === 0) {
            history.push('/signin');
            return;
        }
        setWhichWithdrawal(0);
        setWhichModal(0);
        setOpenModal(true);
    };

    const dashMenu = [
        { icon: RiWallet3Fill, name: 'Recharges', color: '#9e6af8', to: '/deposit-records', id: 400, show: true },
        { icon: RiCalendarTodoFill, name: 'Withdrawals', color: '#50c7cb', to: '/withdrawal-records', id: 500, show: true },
        { icon: RiKeyboardFill, name: 'My team', color: '#8d8ef9', to: '/team', id: 700, show: true },
        { icon: RiRemoteControlFill, name: 'Salary', color: '#8d8ef9', to: '/salary', id: 800, show: salaryData?.useSalary == 1 },
        { icon: RiStockFill, name: 'Agent', color: '#9e6af8', to: '/agent', id: 1, seg: true, show: site_settings?.operational_settings?.useAgent == 1 },
        { icon: RiTelegramFill, name: 'Verified users forum', color: '#123', id: 3, click: () => goToVerifyForum(), seg: true, show: userData?.allowVerifyForum },
        { icon: RiSwapBoxFill, name: 'Swap', color: '#123', id: 5, to: '/swap', seg: true, show: site_settings?.operational_settings?.useSwap == 1 },
        { icon: RiGiftFill, name: 'WelcomeBonus', color: '#123', id: 6, to: '/bonus', seg: true, show: site_settings?.operational_settings?.useBonus == 1 },
        { icon: RiFundsFill, name: 'RechargeReward', color: '#123', id: 7, to: '/reinvest-bonus', seg: true, show: site_settings?.operational_settings?.useReinvestBonus == 1 },
    ];

    const goToVerifyForum = () => {
        setLoading(true);
        setLoadingText('Accessing verified user forum...');
        dispatch(authActions.onLogin(`join/verified-forum`, {}, response => {
            setLoading(false);
            if (response.code !== 1) notify(response.msg, response.code);
            else window.location.href = response?.data?.link;
        }));
    };

    const openAffilliateWithdrawal = () => {
        if ((userData?.emailVerification ?? 0) === 0) {
            history.push('/signin');
            return;
        }
        setWhichWithdrawal(1);
        setWhichModal(0);
        setOpenModal(true);
    };

    const loadAllHomePageData = () => {
        if ((userData?.emailVerification ?? 0) === 0) {
            setLoadingStat(false);
            return;
        }
        dispatch(authActions.onGetHomePageData("user/load/homepage", response => setLoadingStat(false)));
    };

    useEffect(() => {
        const timing = setTimeout(() => loadAllHomePageData(), 2000);
        return () => clearTimeout(timing);
    }, []);

    useEffect(() => {
        if (openModal) {
            window.history.pushState(null, '', window.location.href);
            window.onpopstate = () => {
                window.onpopstate = () => { };
                window.history.back();
                setOpenModal(false);
            };
        }
    }, [openModal]);

    const processWithdrawal = (e) => {
        e.preventDefault();
        if (((selectedCurrencyObj.autoConfirm === 0) && !userData.bank) || ((selectedCurrencyObj.autoConfirm === 1) && !userData.trc20tetherwallet)) {
            notify('Payment details not found. Please bind your payment details.');
            return false;
        }
        if (isNaN(withdrawAmount) || withdrawAmount === 0) return false;
        if (withdrawalPin === '') {
            notify('Please enter your withdrawal pin', 0);
            return false;
        }
        if (selectedCurrency === '') {
            notify('Please mark a Currency', 0);
            return false;
        }
        setLoading(true);
        setLoadingText('Requesting withdrawal');
        dispatch(withdrawalActions.onCreateWithdrawal({ withdrawalPin, amount: withdrawAmount, currency: selectedCurrency, walletAddress }, `withdrawal/new/${whichWithdrawal}`, response => {
            notify(response.msg, response.code);
            if (response.code === 1) {
                setOpenModal(false);
                setWithdrawAmount('');
                setSelectedCurrency('');
                setWithdrawalPin('');
                history.push('/withdrawal-records');
            }
            setLoading(false);
        }));
    };

    const openFunding = () => {
        if ((userData?.emailVerification ?? 0) === 0) {
            history.push('/signin');
            return;
        }
        if (!userData?.trc20tetherwallet && !userData?.bank) {
            notify('Deposit information must be set before recharge', 0);
            history.push('/personal-information');
            return;
        }
        if (userData?.withdrawalPin === 0) {
            notify('Account PIN must be set before recharge', 0);
            history.push('/personal-information');
            return;
        }
        setLoading(true);
        setLoadingText('Refreshing deposit records...');
        dispatch(depositActions.onLoadAllDeposits("deposit/load", response => {
            if (response.code === 1) {
                setLoading(false);
                setWhichModal(1);
                setOpenModal(true);
            }
        }));
    };

    const uploadSelectedPayment = () => {
        setLoadingText('Uploading payment...');
        setLoading(true);
        const depositorName = document.getElementById('depositorName')?.value;
        const depositorAmountPaid = document.getElementById('depositorAmountPaid')?.value;
        const depositorBank = document.getElementById('depositorBank')?.value;
        dispatch(depositActions.onCreateDeposit({ txn_id: getPendingPayment?.[0]?.txn_id, image: depositorFile, depositorAmountPaid, depositorBank, depositorName }, "deposit/manual-deposit-upload", response => {
            setLoading(false);
            notify(response.msg, response.code);
        }));
    };

    const transferFunding = () => {
        if ((userData?.emailVerification ?? 0) === 0) {
            history.push('/signin');
            return;
        }
        setWhichModal(2);
        setOpenModal(true);
    };

    const processTransfer = (e) => {
        e.preventDefault();
        if (selectedCurrency === '') {
            notify('Select Currency', 0);
            return false;
        }
        setLoading(true);
        setLoadingText('Transfering fund...');
        dispatch(authActions.onLogin("fund/transfer/new", { amount: sendingAmount, email: receiverEmail, pin: withdrawalPin, currency: selectedCurrency }, response => {
            if (response.code === 1) {
                setReceiverEmail('');
                setSendingAmount('');
                setWithdrawalPin('');
                setSelectedCurrency('');
                setOpenModal(false);
            }
            setLoading(false);
            notify(response.msg, response.code);
        }));
    };

    const processDeposit = (e) => {
        e.preventDefault();
        if (isNaN(depositAmount) || depositAmount === 0) {
            notify('Enter amount', 0);
            return false;
        }
        if (selectedCurrency === '') {
            notify('Select Currency', 0);
            return false;
        }
        setLoading(true);
        setLoadingText('Generating payment...');
        dispatch(depositActions.onCreateDeposit({ amount: depositAmount, currency: selectedCurrency }, "deposit/new", response => {
            if (response.code === 1) setDepositAmount('');
            else notify(response.msg, response.code);
            setLoading(false);
        }));
    };

    const getPendingPayment = allDepositData?.filter((item) => item.status < 2);

    useEffect(() => {
        const pendingPayment = allDepositData?.filter((item) => item.status < 2);
        setDepositorName(pendingPayment?.[0]?.depositorName);
        setDepositorBank(pendingPayment?.[0]?.depositorBank);
        setDepositorAmountPaid(pendingPayment?.[0]?.depositorAmountPaid);
        setDepositorFile(pendingPayment?.[0]?.image2);
    }, [allDepositData]);

    const deleteInv = (txn_id) => {
        setLoading(true);
        setLoadingText('Deleting request...');
        dispatch(depositActions.onLoadAllDeposits(`deposit/delete/${txn_id}`, response => {
            setLoading(false);
        }));
    };

    const totalEscrow = exchangeStoreData?.filter((item) => item.active === 1).reduce((def, arrItem) => Number(def) + Number(arrItem.crypto_amount), 0);
    const capitals = tradeStoreData?.data?.filter((item) => item.active === 1).reduce((def, arrItem) => Number(def) + Number(arrItem.capital), 0);
    const compundInterest = tradeStoreData?.data?.filter((item) => item.active === 1).reduce((def, arrItem) => Number(def) + Number(arrItem.inTrade), 0);

    const withdrawalChargeHolder = whichWithdrawal === 0 ? 'withdrawalCharge' : 'withdrawalChargeRef';
    const withdrawalChargePercentage = site_settings?.plans?.[0]?.mother[withdrawalChargeHolder];

    const progClickPaymentUpload = () => uploadPayment.current.click();

    const openTradeModal = () => {
        setWhichModal(3);
        setOpenModal(true);
    };

    const tradeNow = () => {
        if (Number(finance?.[0]?.balance) < Number(finance?.[0]?.minimumParticipation)) {
            notify('Balance does not measure up to minimum trade', 0);
            return;
        }
        if (isNaN(tradeAmount) || tradeAmount <= 0) return;
        setLoading(true);
        setLoadingText('Logging trade...');
        dispatch(tradeActions.onPOSTTheTrades({ amount: tradeAmount, currency: selectedPlan?.type }, "trade/new", response => {
            setLoading(false);
            notify(response.msg, response.code);
            if (response.code === 1) {
                setOpenModal(false);
                history.push('/arbitrage');
            }
        }));
    };

    const totalFreq = 24 / selectedPlan?.hoursProfitComesBack;

    const onSuccessOfManualFiatAutoPay = () => loadAllHomePageData();
    const onCloseOfManualFiatAutoPay = () => notify('You closed payment portal', 0);

    const name = userData?.username;
    const phone = userData?.phone;
    const email = userData?.email;

    const amount = getPendingPayment?.[0]?.amountEnforcedToPay * 100;
    const publicKey = site_settings?.site_settings?.paystackPublicKey;
    const reference = getPendingPayment?.[0]?.txn_id;

    const componentProps = { email, amount, reference, metadata: { name, phone }, publicKey, text: "Pay Now" };
    const initializePayment = usePaystackPayment(componentProps);

    const payFiatWithPaystack = () => initializePayment(onSuccessOfManualFiatAutoPay, onCloseOfManualFiatAutoPay);

    const config = {
        public_key: site_settings?.site_settings?.flutterwavePublicKey,
        tx_ref: getPendingPayment?.[0]?.txn_id,
        amount: getPendingPayment?.[0]?.amountEnforcedToPay,
        currency: 'NGN',
        payment_options: 'card,mobilemoney,ussd',
        customer: { email, phonenumber: phone, name },
        customizations: { title: 'my Payment Title', description: 'Payment for items in cart', logo: site_settings?.site_settings?.logo },
    };

    const handleFlutterPayment = useFlutterwave(config);

    const payWithFlutterWave = () => {
        handleFlutterPayment({
            callback: (response) => {
                onSuccessOfManualFiatAutoPay();
                closePaymentModal();
            },
            onClose: () => onCloseOfManualFiatAutoPay(),
        });
    };

    const selectFiatAutoPay = () => {
        if (site_settings?.site_settings?.fiatAutomatedDepositMerchant === 0) payFiatWithPaystack();
        else if (site_settings?.site_settings?.fiatAutomatedDepositMerchant === 1) payWithFlutterWave();
    };

    const startCalculator = () => {
        setWhichModal(4);
        setOpenModal(true);
    };

    const updateCalculatorInput = (inValue) => {
        let newInput = calculatorInput === 0 ? inValue : String(calculatorInput) + String(inValue);
        setCalculatorInput(newInput);
    };

    const calculateNow = () => {
        const roi = Number(selectedPlanCurrencyForCalculator?.[0]?.roi);
        const round = Number(selectedPlanCurrencyForCalculator?.[0]?.totalRound);
        const input = Number(calculatorInput);
        const result = (((roi / 100) * input) + input) * round;
        setCalculatorResult(result);
    };


    // ModernModal Logic Embedded
    const renderModalContent = () => {
        switch (whichModal) {
            case 0: // Withdrawal Modal
                return (
                    <form onSubmit={processWithdrawal}>
                        <DialogContent>
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Select Currency</Typography>
                                <Grid container spacing={2}>
                                    {finance?.map((item) => (
                                        <Grid item xs={12} key={item.type}>
                                            <CurrencyOption
                                                selected={selectedCurrency === item.type}
                                                onClick={() => { setSelectedCurrency(item.type); setSelectedCurrencyObj(item); }}
                                                elevation={selectedCurrency === item.type ? 3 : 1}
                                            >
                                                <Avatar src={item.image} alt={item.name} sx={{ mr: 2 }} />
                                                <Typography variant="subtitle1">{item.name}</Typography>
                                                <Box sx={{ ml: 'auto' }}>
                                                    {item.type === selectedCurrency ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon color="disabled" />}
                                                </Box>
                                            </CurrencyOption>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                            <TextField
                                fullWidth
                                label="Amount"
                                variant="outlined"
                                value={withdrawAmount}
                                onChange={(e) => setWithdrawAmount(e.target.value)}
                                margin="normal"
                                InputProps={{ startAdornment: <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment> }}
                            />
                            <TextField
                                fullWidth
                                label="PIN"
                                variant="outlined"
                                type="password"
                                autoComplete="new-password"
                                onChange={(e) => setWithdrawalPin(e.target.value)}
                                margin="normal"
                                inputProps={{ maxLength: 6 }}
                            />
                        </DialogContent>
                        <DialogActions sx={{ p: 3, pt: 0 }}>
                            <SubmitButton loading={loading} type="submit" />
                        </DialogActions>
                    </form>
                );

            case 1: // Deposit Modal
                return getPendingPayment?.length === 0 ? (
                    <form onSubmit={processDeposit}>
                        <DialogContent>
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Select Currency</Typography>
                                <Grid container spacing={2}>
                                    {finance?.map((item) => (
                                        <Grid item xs={12} key={item.type}>
                                            <CurrencyOption
                                                selected={selectedCurrency === item.type}
                                                onClick={() => { setSelectedCurrency(item.type); setSelectedCurrencyObj(item); }}
                                                elevation={selectedCurrency === item.type ? 3 : 1}
                                            >
                                                <Avatar src={item.image} alt={item.name} sx={{ mr: 2 }} />
                                                <Typography variant="subtitle1">{item.name}</Typography>
                                                <Box sx={{ ml: 'auto' }}>
                                                    {item.type === selectedCurrency ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon color="disabled" />}
                                                </Box>
                                            </CurrencyOption>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                            <TextField
                                fullWidth
                                label="Amount"
                                variant="outlined"
                                value={depositAmount}
                                onChange={(e) => setDepositAmount(e.target.value)}
                                margin="normal"
                                InputProps={{ startAdornment: <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment> }}
                            />
                        </DialogContent>
                        <DialogActions sx={{ p: 3, pt: 0 }}>
                            <SubmitButton loading={loading} type="submit" />
                        </DialogActions>
                    </form>
                ) : (
                    <DialogContent>
                        {getPendingPayment?.map((pendingPayment) => (
                            <Box key={pendingPayment.txn_id}>
                                <Paper sx={{ p: 2, borderRadius: 3, position: 'relative', mb: 3 }}>
                                    {pendingPayment?.status == 0 && (
                                        <IconButton
                                            size="small"
                                            sx={{ position: 'absolute', top: 8, right: 8 }}
                                            color="error"
                                            style={{position: 'absolute', zIndex: '9999999', top: 0, right: -20}}
                                            onClick={() => window.confirm('Clicking OK will delete this invoice permanently. Click OK to confirm deletion') && deleteInv(pendingPayment?.txn_id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    )}
                                    <Box display="flex" alignItems="center" mb={2}>
                                        <Avatar src={finance?.filter((item) => item.type === pendingPayment?.currency)?.[0]?.image} alt={finance?.filter((item) => item.type === pendingPayment?.currency)?.[0]?.name} sx={{ mr: 2 }} />
                                        <Box flexGrow={1}>
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Typography variant="subtitle1">Deposit</Typography>
                                                <Typography variant="subtitle1" fontWeight="bold">{money(pendingPayment?.amountEnforcedToPay, pendingPayment?.currency)}</Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between" alignItems="center">
                                                <Box display="flex" alignItems="center">
                                                    <Typography variant="caption" color="text.secondary" sx={{ mr: 1 }}>{pendingPayment?.txn_id}</Typography>
                                                    {/* <CopyToClipboard text={pendingPayment?.txn_id}>
                                                        <IconButton size="small" onClick={() => notify('Transaction ID copied', 1)}>
                                                            <ContentCopyIcon fontSize="small" />
                                                        </IconButton>
                                                    </CopyToClipboard> */}
                                                </Box>
                                                <Chip label={moneyStatus()[pendingPayment?.status]} size="small" color={pendingPayment?.status === 1 ? "success" : "default"} />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                                        <Typography variant="caption"><CustomAgo time={pendingPayment?.timestamp} /></Typography>
                                        {pendingPayment?.createdWithAutoOrManual === 0 && pendingPayment?.autoConfirm === 0 && (
                                            <Button variant="text" size="small" onClick={() => selectFiatAutoPay()}>Pay Now</Button>
                                        )}
                                    </Box>
                                    <Divider sx={{ my: 2 }} />

                                    <Typography variant="subtitle1" gutterBottom>Details</Typography>
                                    <Grid container spacing={3}>
                                        {pendingPayment?.autoConfirm == 1 && (
                                            <Grid item xs={12} md={6}>
                                                <Box mb={2}>
                                                    <Box display="flex" alignItems="center">
                                                        <Typography variant="body2" sx={{ mr: 1, wordBreak: "break-all" }}>{pendingPayment?.address}</Typography>
                                                        <CopyToClipboard text={pendingPayment?.address}>
                                                            <IconButton size="small" onClick={() => notify('Copied', 1)}>
                                                                <ContentCopyIcon fontSize="small" />
                                                            </IconButton>
                                                        </CopyToClipboard>
                                                    </Box>
                                                    <Typography variant="caption" color="error.main">Send Only USDT TRC20 to this wallet</Typography>
                                                </Box>
                                                <Box display="flex" justifyContent="center" mt={2} p={2} bgcolor="background.paper" width="fit-content">
                                                    <img src={pendingPayment?.qrCode} alt="QR Code" style={{ maxWidth: '150px' }} />
                                                </Box>
                                            </Grid>
                                        )}
                                        {pendingPayment?.createdWithAutoOrManual == 1 && (
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    fullWidth
                                                    label="Name of depositor"
                                                    variant="outlined"
                                                    value={depositorName}
                                                    onChange={(e) => setDepositorName(e.target.value)}
                                                    margin="normal"
                                                    inputProps={{ id: 'depositorName' }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    label={`Depositor ${pendingPayment?.autoConfirm === 0 ? 'Bank' : 'Wallet'} (source)`}
                                                    variant="outlined"
                                                    value={depositorBank}
                                                    onChange={(e) => setDepositorBank(e.target.value)}
                                                    margin="normal"
                                                    inputProps={{ id: 'depositorBank' }}
                                                />
                                                <TextField
                                                    fullWidth
                                                    label="Amount Deposited"
                                                    variant="outlined"
                                                    value={depositorAmountPaid}
                                                    onChange={(e) => setDepositorAmountPaid(e.target.value)}
                                                    margin="normal"
                                                    inputProps={{ id: 'depositorAmountPaid' }}
                                                />
                                                <Box mt={2}>
                                                    <Button
                                                        component="label"
                                                        variant="outlined"
                                                        startIcon={<CloudUploadIcon />}
                                                        onClick={progClickPaymentUpload}
                                                        sx={{ mb: 2 }}
                                                    >
                                                        {pendingPayment?.image == null ? 'Upload' : 'Re-upload'} Payment
                                                        <VisuallyHiddenInput
                                                            ref={uploadPayment}
                                                            type="file"
                                                            accept=".jpg, .gif, .png, .jpeg"
                                                            onChange={(e) => setDepositorFile(e.target.files[0])}
                                                        />
                                                    </Button>
                                                    {depositorFile && (
                                                        <Box mt={1} mb={2}>
                                                            <img
                                                                src={depositorFile === '' ? 'assets/img/image.png' : depositorFile}
                                                                alt="Payment proof"
                                                                style={{ height: '70px', objectFit: 'contain' }}
                                                            />
                                                        </Box>
                                                    )}
                                                </Box>
                                                <SubmitButton loading={loading} onClick={uploadSelectedPayment} type="button" />
                                            </Grid>
                                        )}
                                    </Grid>
                                    {pendingPayment?.autoConfirm == 0 && (
                                        <>
                                            <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                                                <Typography variant="body2">{finance?.filter((item) => item.type === pendingPayment?.currency)?.[0]?.accountNameText}</Typography>
                                                <Typography variant="body2" fontWeight="medium">{finance?.filter((item) => item.type === pendingPayment?.currency)?.[0]?.accountName}</Typography>
                                            </Box>
                                            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                                                <Typography variant="body2">{finance?.filter((item) => item.type === pendingPayment?.currency)?.[0]?.accountNumberText}</Typography>
                                                <Box display="flex" alignItems="center">
                                                    <Typography variant="body2" sx={{ mr: 1 }}>{finance?.filter((item) => item.type === pendingPayment?.currency)?.[0]?.accountNumber}</Typography>
                                                    <CopyToClipboard text={finance?.filter((item) => item.type === pendingPayment?.currency)?.[0]?.accountNumber}>
                                                        <IconButton size="small" onClick={() => notify('Copied', 1)}>
                                                            <ContentCopyIcon fontSize="small" />
                                                        </IconButton>
                                                    </CopyToClipboard>
                                                    <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />
                                                    <Typography variant="body2">{finance?.filter((item) => item.type === pendingPayment?.currency)?.[0]?.bankName}</Typography>
                                                </Box>
                                            </Box>
                                        </>
                                    )}
                                </Paper>
                            </Box>
                        ))}
                    </DialogContent>
                );

            case 2: // Transfer Modal
                return (
                    <form onSubmit={processTransfer}>
                        <DialogContent>
                            <Box sx={{ mb: 3 }}>
                                <Typography variant="subtitle2" color="text.secondary" gutterBottom>Select Currency</Typography>
                                <Grid container spacing={2}>
                                    {finance?.map((item) => (
                                        <Grid item xs={12} key={item.type}>
                                            <CurrencyOption
                                                selected={selectedCurrency === item.type}
                                                onClick={() => { setSelectedCurrency(item.type); setSelectedCurrencyObj(item); }}
                                                elevation={selectedCurrency === item.type ? 3 : 1}
                                            >
                                                <Avatar src={item.image} alt={item.name} sx={{ mr: 2 }} />
                                                <Typography variant="subtitle1">{item.name}</Typography>
                                                <Box sx={{ ml: 'auto' }}>
                                                    {item.type === selectedCurrency ? <CheckCircleIcon color="primary" /> : <RadioButtonUncheckedIcon color="disabled" />}
                                                </Box>
                                            </CurrencyOption>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Box>
                            <TextField
                                fullWidth
                                label="Amount"
                                variant="outlined"
                                value={sendingAmount}
                                onChange={(e) => setSendingAmount(e.target.value)}
                                margin="normal"
                                InputProps={{ startAdornment: <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment> }}
                            />
                            <TextField
                                fullWidth
                                label="3rd Party username"
                                variant="outlined"
                                value={receiverEmail}
                                onChange={(e) => setReceiverEmail(e.target.value)}
                                margin="normal"
                            />
                            <TextField
                                fullWidth
                                label="PIN"
                                variant="outlined"
                                type="password"
                                autoComplete="new-password"
                                onChange={(e) => setWithdrawalPin(e.target.value)}
                                margin="normal"
                                inputProps={{ maxLength: 6 }}
                            />
                        </DialogContent>
                        <DialogActions sx={{ p: 3, pt: 0 }}>
                            <SubmitButton loading={loading} type="submit" />
                        </DialogActions>
                    </form>
                );

            case 3: // Trade Modal
                return (
                    <form onSubmit={() => tradeNow()}>
                        <DialogContent>
                            <Box sx={{ bgcolor: 'background.paper', p: 3, borderRadius: 2, mb: 3 }}>
                                <List>
                                    <ListItem>
                                        <ListItemIcon><CheckCircleIcon color="primary" /></ListItemIcon>
                                        <ListItemText
                                            primary={
                                                <Typography>
                                                    {((Number(selectedPlan?.roi) - 100) * totalFreq).toFixed(1)}%
                                                    <Typography component="span" color="text.secondary" variant="body2" sx={{ ml: 1 }}>daily</Typography>
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><CheckCircleIcon color="primary" /></ListItemIcon>
                                        <ListItemText primary={`${money(selectedPlan?.minimumParticipation, selectedPlan?.type)} minimum trade`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><CheckCircleIcon color="primary" /></ListItemIcon>
                                        <ListItemText primary={`${money(selectedPlan?.maximumParticipation, selectedPlan?.type)} maximum trade`} />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon><CheckCircleIcon color="primary" /></ListItemIcon>
                                        <ListItemText primary={`${totalFreq} rounds of Trading`} />
                                    </ListItem>
                                </List>
                            </Box>
                            <TextField
                                fullWidth
                                label="Enter Amount"
                                variant="outlined"
                                value={tradeAmount}
                                onChange={(e) => setTradeAmount(e.target.value)}
                                margin="normal"
                                InputProps={{ startAdornment: <InputAdornment position="start"><AttachMoneyIcon /></InputAdornment> }}
                            />
                        </DialogContent>
                        <DialogActions sx={{ p: 3, pt: 0 }}>
                            <SubmitButton loading={loading} type="submit" endIcon={<KeyboardArrowRightIcon />} />
                        </DialogActions>
                    </form>
                );

            case 4: // Calculator Modal
                return (
                    <Box p={2}>
                        <CalculatorDisplay elevation={3}>
                            <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                                <IconButton
                                    aria-controls={menuOpen ? 'calculator-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={menuOpen ? 'true' : undefined}
                                    onClick={handleMenuClick}
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    id="calculator-menu"
                                    anchorEl={anchorEl}
                                    open={menuOpen}
                                    onClose={handleMenuClose}
                                    MenuListProps={{ 'aria-labelledby': 'menu-button' }}
                                >
                                    {selectedPlanCurrencyForCalculator?.map((menuItem, menuIndex) => (
                                        <MenuItem key={menuIndex} onClick={handleMenuClose}>
                                            <Typography variant="body2">{menuItem?.name}</Typography>
                                        </MenuItem>
                                    ))}
                                    <MenuItem onClick={() => { setOpenModal(false); handleMenuClose(); }}>
                                        <Typography variant="body2">Close</Typography>
                                    </MenuItem>
                                </Menu>
                                <Typography variant="h6" color="text.secondary">{calculatorInput}</Typography>
                            </Box>
                            <Typography variant="h4">{calculatorResult}</Typography>
                        </CalculatorDisplay>
                        <Box mb={2}>
                            <Grid container spacing={1} justifyContent="center">
                                <Grid item>
                                    <Button
                                        variant="outlined"
                                        color="error"
                                        onClick={() => { setCalculatorInput(0); setCalculatorResult(0); }}
                                        sx={{ minWidth: '64px', height: '48px' }}
                                    >
                                        C
                                    </Button>
                                </Grid>
                                {site_settings?.plans?.map((item, index) => (
                                    <Grid item key={index}>
                                        <Button
                                            variant={selectedMotherCurrencyForCalculator.type === item.mother.type ? "contained" : "outlined"}
                                            onClick={() => { setSelectedMotherCurrencyForCalculator(item.mother); setSelectedPlanCurrencyForCalculator(item?.childs); }}
                                            sx={{ minWidth: '64px', height: '48px' }}
                                        >
                                            {item.mother.name}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Box>
                        <Grid container spacing={1} justifyContent="center">
                            {[7, 8, 9, 4, 5, 6, 1, 2, 3].map((num) => (
                                <Grid item xs={4} key={num}>
                                    <CalculatorButton variant="outlined" onClick={() => updateCalculatorInput(num)}>{num}</CalculatorButton>
                                </Grid>
                            ))}
                            <Grid item xs={4}>
                                <CalculatorButton variant="outlined" onClick={() => updateCalculatorInput('.')}>.</CalculatorButton>
                            </Grid>
                            <Grid item xs={4}>
                                <CalculatorButton variant="outlined" onClick={() => updateCalculatorInput(0)}>0</CalculatorButton>
                            </Grid>
                            <Grid item xs={4}>
                                <CalculatorButton variant="contained" operator onClick={() => calculateNow()}>=</CalculatorButton>
                            </Grid>
                        </Grid>
                    </Box>
                );

            default:
                return <Box p={4}>Unknown modal type</Box>;
        }
    };

    const renderModalHeader = () => {
        if (whichModal === 4) return null;
        return (
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', p: 2, borderBottom: '1px solid', borderColor: 'divider' }}>
                <IconButton edge="start" onClick={() => setOpenModal(false)} aria-label="close" sx={{ mr: 1 }}>
                    <ArrowBackIcon />
                </IconButton>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1, fontWeight: 500 }}>{modalTitle[whichModal]}</Typography>
            </DialogTitle>
        );
    };

    return (
        <SiteWide title={"Home"}>
            <Section hideHeader={true} overlayText={loadingText} loading={loading} title={'Home'}>
                <div className="container" style={{ backgroundColor: '#eee' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <div className="c-4">
                            <WebLink to={'/'} className="logo">
                                <Images height="30px" src={site_settings?.site_settings?.logo} alt="logo" />
                            </WebLink>
                        </div>
                        <div className="c-4" style={{ marginRight: 10 }}>
                            <WebLink onClick={startCalculator} className="header-user" style={{ marginRight: 10 }}>
                                <RiCalculatorLine color={otherColors.text} size={23} />
                            </WebLink>
                            {((userData?.emailVerification ?? 100) !== 100) && (
                                <WebLink onClick={doLogOutNowNow} className="header-user">
                                    <RiLogoutBoxRLine color={otherColors.text} size={23} />
                                </WebLink>
                            )}
                            {((userData?.emailVerification ?? 100) === 100) && (
                                <WebLink to={'/signin'} className="header-user">
                                    <RiLogoutBoxLine color={otherColors.text} size={23} />
                                </WebLink>
                            )}
                        </div>
                    </div>
                </div>

                <div className="container" style={{ paddingBottom: 120, bbbbackground: otherColors.card }}>
                    <Slider arrows={false} {...settings}>
                        {site_settings?.slider_settings?.map((item, index) => (
                            <div className="home-slider-background" key={index}>
                                <a target="_blank" href={item?.linkWhenClicked}>
                                    <Images style={{ borderRadius: 10 }} resizeMode="contain" height="300px" width="100%" source={item?.image} />
                                </a>
                            </div>
                        ))}
                    </Slider>

                    <div style={{ color: '#fff', borderRadius: 10 }} className="mt-40">
                        <div style={{ display: 'flex' }}>
                            <span><RiVolumeUpFill color={otherColors.text} size={26} /></span>
                            <NewsTicker maxRows={1} duration={6000} className="text-info-1">
                                {site_settings?.news?.map((newsItem, newsIndex) => (
                                    <WebLink key={newsIndex} className="text-info-1" to="/news">{newsItem?.subject}</WebLink>
                                ))}
                            </NewsTicker>
                        </div>

                        <BalanceSection
                            finance={finance}
                            onGameCenterClick={() => { }}
                            onDeposit={openFunding}
                            onWithdraw={openWithdrawal}
                            onTransfer={transferFunding}
                            onExchange={site_settings?.operational_settings?.useP2P == 1?() => history.push('/p2p'):null}
                        />
                    </div>

                    {/* <HeadlineComponent title="Earning Hub" subtitle="Choose your next tool" accentColor="#42DCFF" /> */}
                    <MenuGrid dashMenu={dashMenu?.filter(ek => ek.show)} site_settings={site_settings} userData={userData} salaryData={salaryData} otherColors={otherColors} text={'#ffffff'} />
                </div>

                {/* Embedded ModernModal */}
                <StyledDialog
                    open={openModal}
                    onClose={whichModal === 4 ? () => setOpenModal(false) : undefined}
                    maxWidth="sm"
                    fullWidth
                    disableEscapeKeyDown={whichModal !== 4}
                >
                    {renderModalHeader()}
                    {renderModalContent()}
                </StyledDialog>
            </Section>
        </SiteWide>
    );
}

export default Home;
import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Alert,
} from '@mui/material';
import { Visibility, VisibilityOff, LoginOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { authActions } from '../redux/actions';

const Login = () => {
  const [credentials, setCredentials] = useState({
    email: '',
    password: ''
  });
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  
  const dispatch = useDispatch();
  const history = useHistory();

  const handleChange = (prop) => (event) => {
    setCredentials({ ...credentials, [prop]: event.target.value });
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    dispatch(authActions.onLogin(
      "account/login",
      {
        username: credentials.email,
        password: credentials.password
      },
      response => {
        setLoading(false);
        if (response.code === 1) {
          history.replace('/personal-information');
        } else if (response.code === 2) {
          history.replace('/verify-email');
        } else {
          setError(response.msg);
        }
      }
    ));
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        //background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
        background: 'linear-gradient(135deg, #202630 0%, #171e26 100%)',
        position: 'fixed',
        top: 0,
        left: 0,
        overflow: 'auto',
        padding: { xs: 2, sm: 4 }
      }}
    >
      <Card
        elevation={8}
        sx={{
          width: '100%',
          maxWidth: '440px',
          borderRadius: 2,
          backdropFilter: 'blur(20px)',
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          position: 'relative',
          my: 'auto'
        }}
      >
        <CardContent sx={{ p: { xs: 3, sm: 4 } }}>
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography
              variant="h4"
              component="h1"
              sx={{ 
                fontWeight: 'bold',
                color: '#1a237e',
                mb: 1
              }}
            >
              Welcome Back
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              Please sign in to continue
            </Typography>
          </Box>

          {error && (
            <Alert 
              severity="error" 
              sx={{ mb: 3 }}
            >
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Username"
              variant="outlined"
              value={credentials.email}
              onChange={handleChange('email')}
              sx={{ mb: 3 }}
            />

            <TextField
              fullWidth
              label="Password"
              variant="outlined"
              type={showPassword ? 'text' : 'password'}
              value={credentials.password}
              onChange={handleChange('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{ mb: 4 }}
            />

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{
                py: 1.5,
                bgcolor: '#1a237e',
                '&:hover': {
                  bgcolor: '#283593'
                },
                mb: 3
              }}
              startIcon={<LoginOutlined />}
            >
              {loading ? 'Signing in...' : 'Sign In'}
            </Button>
          </form>

          <Box sx={{ textAlign: 'center', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            
            <Typography sstyle={{flex: 1}} variant="body2" color="textSecondary">
              <Button
                color="primary"
                onClick={() => history.push('/reset-password')}
                sx={{ textTransform: 'none' }}
              >
                Forgot Password?
              </Button>
            </Typography>

            <Typography sstyle={{flex: 1}} variant="body2" color="textSecondary">
              <Button
                color="primary"
                onClick={() => history.push('/signup')}
                sx={{ textTransform: 'none' }}
              >
                New Account
              </Button>
            </Typography>

          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default Login;
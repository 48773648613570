import { RiLoginBoxLine, RiHome6Line, RiGlobalLine, RiGamepadLine, RiBarChart2Line, RiUser2Fill, RiSteamFill } from "react-icons/ri";



export const menuItems = [
    {address: '/', title: 'Home', icon: RiHome6Line},
    {address: '/global', title: 'Global', icon: RiGlobalLine},
    {address: '/game-home', title: 'Game', icon: RiGamepadLine},

    {address: '/team', title: 'Interest', icon: RiSteamFill},
    {address: '/personal-information', title: 'Mine', icon: RiUser2Fill},
]


import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  MenuItem,
  CircularProgress,
  Paper,
  Autocomplete,
  InputAdornment,
  Alert,
  Snackbar
} from '@mui/material';
import {
  Phone as PhoneIcon,
  Public as PublicIcon,
  Lock as LockIcon
} from '@mui/icons-material';

import { authActions } from '../redux/actions';
import { userSelect } from '../redux/selectors';
import { staticCountries } from '../utility/countries';
import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";

const PhoneUpdate = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userSelect);

  // Form state
  const [formData, setFormData] = useState({
    country: userData?.country || '',
    phone: userData?.phone || '',
    pin: '',
    phoneCode: '...'
  });

  // UI state
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Form validation
  const [errors, setErrors] = useState({
    phone: '',
    pin: ''
  });

  useEffect(() => {
    if (userData?.country) {
      handleCountryChange(null, staticCountries.find(c => c.id === userData.country));
    }
  }, []);

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setFormData(prev => ({
        ...prev,
        country: newValue.id,
        phoneCode: newValue.phonecode
      }));
    }
  };

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    setFormData(prev => ({ ...prev, [field]: value }));
    
    // Validation
    if (field === 'phone') {
      setErrors(prev => ({
        ...prev,
        phone: /^\d{10,}$/.test(value) ? '' : 'Please enter a valid phone number'
      }));
    } else if (field === 'pin') {
      setErrors(prev => ({
        ...prev,
        pin: value.length === 6 ? '' : 'PIN must be 6 digits'
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (errors.phone || errors.pin) return;
    
    setLoading(true);
    
    try {
      dispatch(authActions.onLogin(
        "account/update_phone",
        {
          country: formData.country,
          phone: formData.phone,
          pin: formData.pin
        },
        response => {
          setSnackbar({
            open: true,
            message: response.msg,
            severity: response.code === 1 ? 'success' : 'error'
          });
          
          if (response.code === 1) {
            setFormData(prev => ({ ...prev, pin: '' }));
          }
          
          setLoading(false);
        }
      ));
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'An error occurred. Please try again.',
        severity: 'error'
      });
      setLoading(false);
    }
  };

  return (<SiteWide>
            <Section overlayText={'Loading...'} loading={false} title={'Update Phone'}>
                <Container maxWidth="sm">
                <Paper elevation={0} sx={{ p: 4, mt: 4 }}>

                    <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Autocomplete
                        options={staticCountries}
                        getOptionLabel={(option) => option.nicename}
                        value={staticCountries.find(c => c.id === formData.country) || null}
                        onChange={handleCountryChange}
                        renderInput={(params) => (
                        <TextField
                            {...params}
                            margin="normal"
                            fullWidth
                            label="Country"
                            required
                            InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <>
                                <InputAdornment position="start">
                                    <PublicIcon color="primary" />
                                </InputAdornment>
                                {params.InputProps.startAdornment}
                                </>
                            )
                            }}
                        />
                        )}
                    />

                    <TextField
                        margin="normal"
                        fullWidth
                        label="Phone Number"
                        value={formData.phone}
                        onChange={handleInputChange('phone')}
                        error={!!errors.phone}
                        helperText={errors.phone}
                        required
                        InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            <PhoneIcon color="primary" />
                            {formData.phoneCode}
                            </InputAdornment>
                        )
                        }}
                    />

                    <TextField
                        margin="normal"
                        fullWidth
                        label="PIN"
                        type="password"
                        value={formData.pin}
                        onChange={handleInputChange('pin')}
                        error={!!errors.pin}
                        helperText={errors.pin}
                        required
                        inputProps={{ maxLength: 6 }}
                        InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                            <LockIcon color="primary" />
                            </InputAdornment>
                        )
                        }}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        size="large"
                        disabled={loading || !!errors.phone || !!errors.pin}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {loading ? (
                        <CircularProgress size={24} color="inherit" />
                        ) : (
                        'Update Phone Number'
                        )}
                    </Button>
                    </Box>
                </Paper>

                <Snackbar
                    open={snackbar.open}
                    autoHideDuration={6000}
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                >
                    <Alert
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                    severity={snackbar.severity}
                    variant="filled"
                    >
                    {snackbar.message}
                    </Alert>
                </Snackbar>
                </Container>
            </Section>
        </SiteWide>
  );
};

export default PhoneUpdate;
import React, {useState} from "react";
import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import { useSelector, useDispatch } from 'react-redux';

import {authActions} from '../../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect} from '../../redux/selectors/index';
import Helper1, {notify, money, customNormalModalStyles, capitalizeFirstLetter} from '../../utility/Helper1';
import {homepage} from '../../utility/APIRequests';


import Countdown from 'react-countdown';

import {CopyToClipboard} from 'react-copy-to-clipboard';
import copy from 'copy-to-clipboard';

import Modal from 'react-modal';
import {staticCountries} from '../../utility/countries';
import {Images} from "../customComponents";
import Slider from "react-slick";
import { RiPhoneLockFill, RiBankCardFill, RiArrowRightCircleFill, RiFileCopyFill, RiBankFill,RiStackshareLine, RiWallet3Fill, RiArrowGoBackLine, RiEarthLine, RiLockUnlockLine, RiAccountBoxFill, RiSecurePaymentLine, RiListOrdered, RiLockPasswordFill, RiLoginBoxFill, RiMouseLine } from "react-icons/ri";
import {mainStyle, mainColor, header, footer, otherColors} from '../../utility/styles';
import { useHistory } from 'react-router-dom'; // version 5.2.0

import { FcSurvey, FcTwoSmartphones, FcDiploma1, FcCurrencyExchange, FcKey, FcLock } from "react-icons/fc";
import FAB from './FAB'

import { 
    Box,
    Card,
    Container,
    Avatar,
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListItemSecondaryAction,
    IconButton,
    Skeleton,
    useTheme
  } from '@mui/material';
  import { ChevronRight } from 'lucide-react';

function UserSetting(){

    const userData = useSelector(userSelect);
    const site_settings = useSelector(allRemoteSettingsSelect);
    const finance = useSelector(homeScreenStatSelect);
    const history = useHistory();

    const [financeEditing, setFinanceEditing]   = useState(0);
    const [walletModal, setWalletModal]   = useState(false);
    const [withdrawalPinModal, setWithdrawalPinModal]   = useState(false);
    const [withdrawalPin, setWithdrawalPin]   = useState('');
    const [withdrawalPin2, setWithdrawalPin2]   = useState('');
    const [emailOTP, setEmailOTP]   = useState('');
    const [phoneModal, setPhoneModal]   = useState(false);
    
    const [phone, setPhone]   = useState(userData?.phone ?? '');
    const [email, setEmail]   = useState(userData?.email ?? '');
    const [phoneCode, setPhoneCode]   = useState('...');
    const [country, setCountry]             = useState(userData?.country ?? ''); //'156'

    const [passwordModal, setPasswordModal]   = useState(false);
    const [loading, setloading]   = useState();
    const [loadingText, setLoadingText]   = useState('Loading...');

    const [password, setPassword]       = useState('');

    const [wallet, setWallet]       = useState(userData?.trc20tetherwallet ?? '');
    const [bank, setBank]       = useState(userData?.bank ?? '');
    const [accountName, setAccountName]       = useState(userData?.accountName ?? '');
    const [accountNumber, setAccountNumber]       = useState(userData?.accountNumber ?? '');
    const theme = useTheme();
    const dispatch = useDispatch();

    const hiddenKYCInput = React.useRef(null);

    const doPasswordChange = async (e) => {

        if(password == ''){
            notify('Please input new password', 0);
            return false;
        }
        setloading(true);
        e.preventDefault();
        dispatch(authActions.onLogin("account/change_password", {password, pin: withdrawalPin}, response => {
            if(response.code == 1){
                setPassword('');
                setWithdrawalPin('');
            }
            notify(response.msg, response.code)
            setloading(false);
        }),
        );

        
    }






    const doAddressChange = (e) => {
        setloading(true);
        e.preventDefault();

        let updateObject;

        if(userData.depositMethod == 1){
            updateObject = {wallet}
        }else{
            updateObject = {bank, accountName, accountNumber, pin: withdrawalPin}
        }

        dispatch(authActions.onLogin(`account/account_change/${financeEditing}`, updateObject, response => {
            notify(response.msg, response.code)
            setloading(false);
            if(response.code == 1){
                setWithdrawalPin('')
            }
        }),
        );
    }


    const doWithdrawalPinChange = () => {

        if(withdrawalPin != withdrawalPin2){
            notify('PIN mismatch. Please re-validate PIN.')
            return;
        }
        
        setloading(true)
        setLoadingText('Changing Withdrawal PIN...')

        dispatch(authActions.onLogin(`account/withdrawal-pin/change`, {emailOTP, withdrawalPin}, response => {
            notify(response.msg, response.code)
            setloading(false);
            if(response.code == 1){  setWithdrawalPinModal(false); setWithdrawalPin(''); }
        }),
        );

    }

    const getNewEmailOTP = () => {
        // setloading(true)
        // setLoadingText('Sending confirmation')

        // dispatch(authActions.onLogin(`account/send-email-otp/withdrawal-pin`, {}, response => {
        //     // notify(response.msg, response.code)
        //     setloading(false);
            setWithdrawalPinModal(true)
        // }),
        // );

    }


    const popUpAccountModal = async (financeWo) => {
        setFinanceEditing(financeWo)
        setWalletModal(true)
    }


    const popUpPhoneModal = () => {
        setPhoneModal(true)
    }


    const saveBTNJSX = (callback, close) => (
        <>
            <button onClick={callback}  type="button" disabled={loading} className="btn btn-block btn-primary" style={{backgroundColor: otherColors.btn1}}>
            OK </button>

        </>
        )

    const closeModal = (close) => {
        return false;
        // <div className="text-center mt-40"><WebLink onClick={() => close(false)} className="btn btn- btn-danger cursor-pointer">Close</WebLink></div>
    }

    const doPhoneChange = () => {
        setloading(true);
        setLoadingText('Updating phone...')
        dispatch(authActions.onLogin("account/update_phone", {country, phone, pin: withdrawalPin}, response => {
            if(response.code == 1){
                setWithdrawalPin('')
            }
            notify(response.msg, response.code)
            setloading(false);
        })
        );
    }

    const retrieveDepositBonus = () => {
        setloading(true);
        setLoadingText('Retrieving deposit bonus...')

        dispatch(authActions.onLogin(`withdraw/bonus`, {currency: finance?.[0]?.type}, response => {
            notify(response.msg, response.code)
            if(response.code == 1){
                
            }
            setloading(false);
        }))

    }


    React.useEffect(() => {
        changeCountry(country)
    }, [])

    const changeCountry = (countryId) =>{
        let selectedCountry = staticCountries.filter(e => e.id == countryId) 
        setCountry(countryId);
        let countryPhoneCode = selectedCountry?.[0]?.phonecode;
        setPhoneCode(countryPhoneCode);

    }


    const kycStatus = () => {
        let statusText = 0;
        let newArray = userData?.kyc?.map((item, index) => {
            if(item.status == 2){
                statusText = 'Approved';
            }else if(item.status == 1){
                statusText = 'Processing';
            }else if(item.status == 0){
                statusText = 'Pending';
            }
        })

        return statusText;
    }



    const loadAllHomePageData = () => {

        if((userData?.emailVerification ?? 0) == 0){ return; }

        dispatch(authActions.onGetHomePageData("user/load/homepage", response => {

        }))

    }

    React.useEffect(() => {

        let timing = setTimeout(() => {
            loadAllHomePageData();
        }, 1000)
        
        return () => clearTimeout(timing);
    }, [])

    const pinValidator = () => {
        return <div>
            <div class="form-group">
            <span className="text-info-1">PIN</span>
                <input maxLength={6} autoComplete="new-password" placeholder="Pin" onChange = {e => setWithdrawalPin(e.target.value) } required="" className="auth-inpu  form-field form-control" type="password" />
            
        </div></div>
    }

    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
        }]
    };


    const goHome = () => {
        window.location.href = homepage;
    }

    const copyNow = () => {
        let userRef = site_settings?.site_settings?.site_url+'/#/signup?ref='+userData?.myrefid
        copy(userRef)
        notify("copied Link", 1);
    }
    const dashMenu = [
        {
            icon: FcSurvey,
            name: 'Referral Link',
            color: '#206afc',
            to: () => copyNow(),
            value: userData?.myrefid
        },
        {
            icon: FcTwoSmartphones,
            name: 'Update profile',
            color: '#206afc',
            to: () => history.push('/phone'),
            value: userData?.phone?.substring(0,2) + '****'
        },
        {
            icon: FcDiploma1,
            name: 'Bind Account',
            color: '#9e6af8',
            to: () => history.push('/bank'),
        },
        {
            icon: FcCurrencyExchange,
            name: 'TRC20 Wallet',
            color: '#9e6af8',
            to: () => history.push('/wallet'),
        },
        {
            icon: FcLock,
            name: 'Change password',
            color: '#f70',
            to: () => history.push('change-password'),
        },
        {
            icon: FcKey,
            name: 'Update PIN',
            color: '#392',
            to: () => history.push('change-pin'),
        },
        // {
        //     icon: RiEarthLine,
        //     name: 'Home',
        //     color: '#ccc23a',
        //     to: () => goHome(),
        // },
        // {
        //     icon: RiStackshareLine,
        //     name: 'About',
        //     color: '#AE95A4',
        //     to: () => history.push('/about'),
        // },

        
        
    ]


    

    return (<SiteWide>
        <Section overlayText={'Loading...'} loading={false} title={'Account'}>
        
            <Container maxWidth="md" sx={{ py: 4 }}>
            <Box sx={{ mb: 4, display: 'flex', alignItems: 'center', gap: 2 }}>
                <Avatar
                sx={{
                    width: 56,
                    height: 56,
                    bgcolor: theme.palette.primary.main
                }}
                >
                {capitalizeFirstLetter(userData?.username?.[0])}
                </Avatar>
                <Box>
                <Typography variant="h5" fontWeight={600}>
                    Welcome, {capitalizeFirstLetter(userData?.username)}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                    Manage your account settings and preferences
                </Typography>
                </Box>
            </Box>
                
                <List sx={{ p: 0 }}>
                    {dashMenu.map((item, index) => {
                    const Icon = item.icon;
                    
                    return (
                        <Card
                        key={index}
                        sx={{
                            mb: 2,
                            bgcolor: 'background.paper',
                            transition: 'all 0.2s',
                            '&:hover': {
                            transform: 'translateY(-2px)',
                            boxShadow: theme.shadows[4],
                            '& .MuiIconButton-root': {
                                transform: 'translateX(4px)',
                            }
                            }
                        }}
                        >
                        <ListItem
                            button
                            onClick={item.to}
                            sx={{
                            py: 2,
                            px: 3,
                            }}
                        >
                            <ListItemIcon sx={{ minWidth: 40 }}>
                            <Icon size={24} />
                            </ListItemIcon>
                            
                            <ListItemText
                            primary={item.name}
                            sx={{
                                '& .MuiTypography-root': {
                                fontWeight: 500,
                                color: 'text.primary'
                                }
                            }}
                            />
                            
                            {item.value && (
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{ mr: 2 }}
                            >
                                {item.value}
                            </Typography>
                            )}
                            
                            <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                sx={{
                                transition: 'transform 0.2s',
                                color: 'primary.main'
                                }}
                            >
                                <ChevronRight />
                            </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                        </Card>
                    );
                    })}
                </List>
                
                <div style={{position: 'absolute', right: 10, bottom: 60}}>
                    <FAB />
                </div>
                
            </Container>
        </Section>
    </SiteWide>
    );

}

export default UserSetting;
import React, { useState } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  Button,
  LinearProgress,
  Chip
} from '@mui/material';
import {
  Casino,
  Gamepad,
  ArrowUpward,
  ArrowDownward,
  MoreVert,
  SwapHoriz,
  CurrencyExchange
} from '@mui/icons-material';
import { sumFieldInArray } from '../../utility/Helper1';
import GameCenterBanner from './GameCenter';
import { RiCheckboxIndeterminateLine, RiMore2Fill, RiCalculatorLine, RiArrowDropRightLine, RiLogoutBoxLine, RiLogoutBoxRLine, RiVolumeUpFill, RiContactsBookUploadLine, RiNewspaperLine, RiIncreaseDecreaseLine, RiFundsFill, RiGiftFill, RiSwapBoxFill, RiMapPinRangeFill, RiTelegramFill, RiWhatsappFill, RiStockFill, RiRemoteControlFill, RiInstallFill, RiKeyboardFill, RiCheckboxLine, RiArrowLeftLine, RiShareForward2Fill, RiBattery2ChargeLine, RiBatteryShareFill, RiCastLine, RiDownload2Line, RiNotification3Line, RiArrowDownCircleFill, RiMailCheckFill, RiErrorWarningFill, RiDeleteBin2Fill, RiMoneyDollarCircleLine, RiFileCopy2Fill, RiGiftLine, RiSecurePaymentLine, RiRecordCircleFill, RiShieldCrossLine, RiUser4Fill, RiExternalLinkFill, RiContactsBookFill,
RiWallet3Fill, RiCloseCircleFill, RiCalendarTodoFill, RiArrowGoBackLine, RiGasStationFill, RiBankCard2Line, RiShieldUserFill, RiTeamFill, RiTicket2Line, RiUserAddFill } from "react-icons/ri";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const BalanceSection = ({ 
  finance = [], 
  onGameCenterClick = () => {},
  onDeposit = () => {},
  onWithdraw = () => {},
  onTransfer = () => {},
  onExchange = null
}) => {
  // Menu state
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
const history = useHistory()

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAction = (action) => {
    handleMenuClose();
    if (action === 'transfer') onTransfer();
    if (action === 'exchange') onExchange();
  };

  // const onDeposit = () => {
  //   history.push('/deposit')
  // }
  return (
    <Box sx={{ p: 0 }}>
      <Card 
        elevation={3}
        sx={{
          background: 'linear-gradient(135deg, #2c1f4a 0%, #1a1025 100%)',
          color: 'white',
          mb: 3,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 3,
          border: '2px solid rgba(255, 255, 255, 0.1)'
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            top: -20,
            right: -20,
            opacity: 0.1,
            transform: 'rotate(-15deg)'
          }}
        >
          <Gamepad sx={{ fontSize: 180 }} />
        </Box>
        
        <CardContent>
          <Stack style={{marginBottom: 16}} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h6" sx={{ 
            //   mb: 2,
              display: 'flex',
              alignItems: 'center',
              color: '#fff',
              gap: 1
            }}>
              <Casino sx={{ color: '#ffd700' }} />
              Account Balance
              <Chip 
                label="USDT" 
                size="small" 
                sx={{ 
                  bgcolor: 'rgba(76, 175, 80, 0.3)',
                  color: '#4caf50',
                  border: '1px solid #4caf50'
                }}
              />
            </Typography>
            
            <IconButton 
              onClick={handleMenuClick}
              sx={{ color: 'white' }}
            >
              <MoreVert />
            </IconButton>
            
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => handleAction('transfer')}>
                <SwapHoriz sx={{ mr: 1 }} /> Transfer
              </MenuItem>
              {onExchange && <MenuItem onClick={() => handleAction('exchange')}>
                <CurrencyExchange sx={{ mr: 1 }} /> Exchange
              </MenuItem>}
            </Menu>
          </Stack>

          <Typography 
            variant="h3" 
            sx={{ 
              mb: 2, 
              fontWeight: 'bold',
              color: '#ffd700',
              textShadow: '0 0 10px rgba(255, 215, 0, 0.3)'
            }}
          >
            ${sumFieldInArray(finance, 'balance')?.toLocaleString()}
          </Typography>

          {/* <LinearProgress 
            variant="determinate" 
            value={70} 
            sx={{ 
              mb: 1, 
              height: 8,
              borderRadius: 4,
              bgcolor: 'rgba(255,255,255,0.1)',
              '& .MuiLinearProgress-bar': {
                bgcolor: '#ffd700',
                backgroundImage: 'linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent)',
                backgroundSize: '1rem 1rem',
                animation: 'progress-animation 1s linear infinite'
              }
            }} 
          /> */}

          {/* <Stack direction="row" justifyContent="space-between" sx={{ mb: 3 }}>
            <Typography variant="body2">Level Progress</Typography>
            <Typography variant="body2" sx={{ color: '#ffd700' }}>
              70%
            </Typography>
          </Stack> */}

          <Stack direction="row" spacing={2}>
            <Button
              variant="contained"
              startIcon={<RiBattery2ChargeLine />}
              onClick={onDeposit}
              sx={{
                flex: 1,
                bgcolor: 'rgba(76, 175, 80, 0.9)',
                '&:hover': {
                  bgcolor: 'rgba(76, 175, 80, 1)'
                }
              }}
            >
              Recharge
            </Button>
            <Button
              variant="contained"
              startIcon={<RiBatteryShareFill />}
              onClick={onWithdraw}
              sx={{
                flex: 1,
                bgcolor: 'rgba(255, 152, 0, 0.9)',
                '&:hover': {
                  bgcolor: 'rgba(255, 152, 0, 1)'
                }
              }}
            >
              Withdraw
            </Button>
          </Stack>
        </CardContent>
      </Card>

      <GameCenterBanner onGameCenterClick={onGameCenterClick} />
      
      <style jsx global>{`
        @keyframes progress-animation {
          0% {
            background-position: 1rem 0;
          }
          100% {
            background-position: 0 0;
          }
        }
      `}</style>
    </Box>
  );
};

export default BalanceSection;
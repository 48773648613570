import React from 'react';
import {
  Box,
  Grid,
  Card,
  Typography,
  keyframes,
  alpha
} from '@mui/material';
import { Link } from 'react-router-dom';

// Animation keyframes
const glowPulse = keyframes`
  0%, 100% {
    box-shadow: 0 0 15px rgba(66, 220, 255, 0.2);
  }
  50% {
    box-shadow: 0 0 25px rgba(66, 220, 255, 0.4);
  }
`;

const iconFloat = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`;

const MenuGrid = ({ 
  dashMenu = [], 
  site_settings = {}, 
  userData = {}, 
  salaryData = {},
  otherColors = {}
}) => {
  // Helper function to check if menu item should be visible
  const isMenuItemVisible = (item) => {
    if (item.id === 1 && site_settings?.operational_settings?.useAgent === 0) return false;
    if (item.id === 5 && site_settings?.operational_settings?.useSwap === 0) return false;
    if (item.id === 3 && !userData?.allowVerifyForum) return false;
    if (item.id === 4 && (site_settings?.site_settings?.useGoogleMap ?? 0) === 0) return false;
    if (item.id === 6 && (site_settings?.operational_settings?.useBonus ?? 0) === 0) return false;
    if (item.id === 7 && (site_settings?.operational_settings?.useReinvestBonus ?? 0) === 0) return false;
    if (item.id === 800 && salaryData?.useSalary === 0) return false;
    if (item.id === 600 && site_settings?.operational_settings?.useP2P === 0) return false;
    return true;
  };


  // #172637

  return (
    <Box 
      sx={{ 
        p: 0,
        marginTop: 2,
      }}
    >
      <Grid container spacing={2}>
        {dashMenu.map((item, index) => {
          if (!isMenuItemVisible(item)) return null;
          
          const Icon = item.icon;
          
          const ClickMyLife = item.to?Link:'div'
          
          return (
            <Grid item xs={6} sm={4} md={3} key={index} style={{}}>
              <ClickMyLife 
                to={item.to}
                style={{ textDecoration: 'none' }}
                onClick={item?.click}
              >
                <Card
                  sx={{
                    background: '#172637',
                    // background: `linear-gradient(135deg, ${alpha(otherColors.text, 0.1)} 0%, ${alpha(otherColors.text, 0.05)} 100%)`,
                    backdropFilter: 'blur(10px)',
                    border: '1px solid',
                    borderColor: 'transparent',
                    borderRadius: '16px',
                    overflow: 'hidden',
                    position: 'relative',
                    transition: 'all 0.3s ease-in-out',
                    cursor: 'pointer',
                    '&:before': {
                      content: '""',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: `linear-gradient(45deg, transparent 0%, ${alpha(otherColors.text, 0.1)} 50%, transparent 100%)`,
                      opacity: 0,
                      transition: 'opacity 0.3s ease-in-out',
                    },
                    '&:hover': {
                      transform: 'translateY(-5px)',
                      borderColor: alpha(otherColors.text, 0.2),
                      animation: `${glowPulse} 2s infinite`,
                      '&:before': {
                        opacity: 1,
                      },
                      '& .menu-icon': {
                        animation: `${iconFloat} 2s infinite ease-in-out`,
                        color: otherColors.text,
                      },
                      '& .menu-text': {
                        color: otherColors.text,
                      }
                    }
                  }}
                >
                  <Box
                    sx={{
                      p: 3,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      gap: 2,
                    }}
                  >
                    <Box
                      className="menu-icon"
                      sx={{
                        width: 60,
                        height: 60,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        borderRadius: '12px',
                        background: alpha(otherColors.text, 0.1),
                        transition: 'all 0.3s ease-in-out',
                      }}
                    >
                      <Icon 
                        style={{ 
                          fontSize: 28,
                          color: alpha(otherColors.text, 0.8),
                          transition: 'all 0.3s ease-in-out',
                        }}
                      />
                    </Box>
                    
                    <Typography
                      className="menu-text"
                      variant="subtitle1"
                      sx={{
                        color: alpha(otherColors.text, 0.8),
                        // color: '#fff',
                        fontWeight: 500,
                        textAlign: 'center',
                        transition: 'all 0.3s ease-in-out',
                      }}
                    >
                      {item.name}
                    </Typography>
                  </Box>

                  {/* Decorative corner accents */}
                  {['top-left', 'top-right', 'bottom-left', 'bottom-right'].map((corner) => (
                    <Box
                      key={corner}
                      sx={{
                        position: 'absolute',
                        width: 4,
                        height: 4,
                        background: alpha(otherColors.text, 0.3),
                        borderRadius: '50%',
                        ...(corner === 'top-left' && { top: 8, left: 8 }),
                        ...(corner === 'top-right' && { top: 8, right: 8 }),
                        ...(corner === 'bottom-left' && { bottom: 8, left: 8 }),
                        ...(corner === 'bottom-right' && { bottom: 8, right: 8 }),
                      }}
                    />
                  ))}
                </Card>
              </ClickMyLife>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default MenuGrid;
import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import { notify } from '../utility/Helper1';
import { otherColors } from '../utility/styles';
import Section from "./layouts/Section";
import { SiteWide } from "./customComponents";

// Material UI imports
import {
  Typography,
  TextField,
  Button,
  Box,
  Paper,
  Container,
  Divider,
  Stack,
  alpha,
  styled
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PersonIcon from '@mui/icons-material/Person';
import DescriptionIcon from '@mui/icons-material/Description';

// Custom styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  backgroundColor: otherColors.cotpBlue,
  borderRadius: theme.spacing(2),
  padding: theme.spacing(3),
  color: '#fff',
  marginBottom: theme.spacing(3),
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: alpha('#fff', 0.5),
    },
    '&:hover fieldset': {
      borderColor: '#fff',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#fff',
    },
  },
  '& .MuiInputLabel-root': {
    color: alpha('#fff', 0.7),
  },
  '& .MuiOutlinedInput-input': {
    color: '#fff',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#fff',
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: otherColors.btn1,
  padding: theme.spacing(1.5),
  fontWeight: 'bold',
  marginTop: theme.spacing(4),
  '&:hover': {
    backgroundColor: alpha(otherColors.btn1, 0.8),
  },
}));

function Agent() {
  const userData = useSelector(userSelect);
  const [loadingText, setLoadingText] = useState('Updating...');
  const [loading, setLoading] = useState(false);
  
  const [phone, setPhone] = useState(userData?.agent_phone ?? '');
  const [location, setLocation] = useState(userData?.agent_location ?? '');
  const [fullname, setFullname] = useState(userData?.agent_fullname ?? '');
  const [desc, setDesc] = useState(userData?.agent_brief_desc ?? '');

  const site_settings = useSelector(allRemoteSettingsSelect);
  const dispatch = useDispatch();

  const applyForAgent = () => {
    setLoading(true);
    setLoadingText('Please wait...');

    dispatch(authActions.onLogin(`account/agent/update`, { phone, location, fullname, desc }, response => {
      notify(response.msg, response.code);
      if (response.code == 1) {
        // Handle success
      }
      setLoading(false);
    }));
  };

  // Create a theme that works with the existing styles
  const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
        main: '#fff',
      },
    },
    typography: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      h4: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: 500,
      }
    },
    components: {
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: alpha('#fff', 0.2),
            margin: '16px 0',
          },
        },
      },
    },
  });

  return (
    <SiteWide title="Become an Agent">
      <Section overlayText={loadingText} loading={loading} title={'Become an Agent'}>
        <ThemeProvider theme={theme}>
          <Container maxWidth="md" sx={{ py: 2 }}>
            <StyledPaper elevation={3}>
              <Typography variant="h5" component="h2" gutterBottom>
                {site_settings?.operational_settings?.agentHeadline || 'Become an Agent'}
              </Typography>
              
              <Divider />
              
              <Typography variant="body1" component="div" sx={{ my: 2, lineHeight: 1.7 }}>
                {site_settings?.operational_settings?.agentContent || 
                  'Join our agent program to expand your business opportunities and earn additional income.'}
              </Typography>
            </StyledPaper>

            <StyledPaper elevation={3}>
              <Box component="form" noValidate>
                <Stack spacing={3}>
                  <StyledTextField
                    required
                    fullWidth
                    label="Phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    InputProps={{
                      startAdornment: <PhoneIcon sx={{ mr: 1, color: alpha('#fff', 0.7) }} />,
                    }}
                  />
                  
                  <StyledTextField
                    required
                    fullWidth
                    label="Location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    multiline
                    rows={2}
                    inputProps={{ maxLength: 120 }}
                    InputProps={{
                      startAdornment: <LocationOnIcon sx={{ mr: 1, color: alpha('#fff', 0.7) }} />,
                    }}
                  />
                  
                  <StyledTextField
                    required
                    fullWidth
                    label="Full Name"
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                    InputProps={{
                      startAdornment: <PersonIcon sx={{ mr: 1, color: alpha('#fff', 0.7) }} />,
                    }}
                  />
                  
                  <StyledTextField
                    required
                    fullWidth
                    label="Reason for application"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    multiline
                    rows={4}
                    inputProps={{ maxLength: 300 }}
                    helperText={`${desc.length}/300`}
                    FormHelperTextProps={{
                      sx: { color: alpha('#fff', 0.7), textAlign: 'right' }
                    }}
                    InputProps={{
                      startAdornment: <DescriptionIcon sx={{ mr: 1, color: alpha('#fff', 0.7) }} />,
                    }}
                  />
                </Stack>
                
                <SubmitButton
                  variant="contained"
                  fullWidth
                  size="large"
                  onClick={applyForAgent}
                  disabled={loading}
                  endIcon={<SendIcon />}
                >
                  Submit Application
                </SubmitButton>
              </Box>
            </StyledPaper>
          </Container>
        </ThemeProvider>
      </Section>
    </SiteWide>
  );
}

export default Agent;
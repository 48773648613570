import React from 'react';
import {
  Modal,
  Box,
  Typography,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
} from '@mui/material';
import { X } from 'lucide-react';

const HelpModal = ({ open, onClose }) => {
  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 600,
    maxHeight: '90vh',
    overflow: 'auto',
    bgcolor: 'background.paper',
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  const rules = [
    {
      type: 'Green',
      condition: '1, 3, 7, 9',
      multiplier: '2x (196)',
      special: '5: 1.5x (147)',
    },
    {
      type: 'Red',
      condition: '2, 4, 6, 8',
      multiplier: '2x (196)',
      special: '0: 1.5x (147)',
    },
    {
      type: 'Violet',
      condition: '0 or 5',
      multiplier: '2x (196)',
      special: '-',
    },
    {
      type: 'Number',
      condition: 'Exact match',
      multiplier: '9x (882)',
      special: '-',
    },
    {
      type: 'Big',
      condition: '5, 6, 7, 8, 9',
      multiplier: '2x (196)',
      special: '-',
    },
    {
      type: 'Small',
      condition: '0, 1, 2, 3, 4',
      multiplier: '2x (196)',
      special: '-',
    },
  ];

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="help-modal-title"
      aria-describedby="help-modal-description"
      style={{backgroundColor: 'rgba(0, 0, 0, 0.7)'}}
    >
      <Box sx={modalStyle}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Typography variant="h5" component="h2" fontWeight="bold">
            Game Rules & Information
          </Typography>
          <IconButton onClick={onClose} size="small">
            <X size={20} />
          </IconButton>
        </Box>

        <Box mb={4}>
          <Typography variant="h6" gutterBottom>
            Game Schedule
          </Typography>
          <Typography variant="body1" paragraph>
            • Each round lasts 5 minutes (4:55 for orders, 0:05 for draw)
            • Available 24/7
            • Total daily rounds: 288
          </Typography>
          
          <Typography variant="h6" gutterBottom>
            Investment Details
          </Typography>
          <Typography variant="body1" paragraph>
            • Example investment: $100
            • Service fee: 2%
            • Contract amount after fee: $98
          </Typography>
        </Box>

        <Typography variant="h6" gutterBottom>
          Winning Rules
        </Typography>
        
        <TableContainer component={Paper} variant="outlined">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell><strong>Selection</strong></TableCell>
                <TableCell><strong>Winning Numbers</strong></TableCell>
                <TableCell><strong>Multiplier</strong></TableCell>
                {/* <TableCell><strong>Special Case</strong></TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rules.map((rule) => (
                <TableRow key={rule.type}>
                  <TableCell>{rule.type}</TableCell>
                  <TableCell>{rule.condition}</TableCell>
                  <TableCell>{rule.multiplier}</TableCell>
                  {/* <TableCell>{rule.special}</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Modal>
  );
};

export default HelpModal;
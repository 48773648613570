import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {show, hide} from "../components/customComponents/Notify";
import {mainStyle, mainColor, header, footer, otherColors} from './styles';
import { BsArrowLeftSquare, BsArrowRightSquare } from "react-icons/bs";



class Helper1{


  status = () => {
    return {0:'Awaiting approval', 1:'Active', 2:'Suspended'};
  }

}


export const notify = (msg, code) => {
    show(msg, code)
  
}


export const niceDate = (paramObj) => {
  const dateObj = new Date(paramObj);
  return dateObj.toDateString() + ' ' + dateObj.getHours()+ ':' + dateObj.getMinutes();
}

export const money = (amount, currency, naira = false) => {
  var nf = new Intl.NumberFormat();
  let syn = [170, 171, 177]?.includes(Number(currency))?'$':'₦';
  return syn + (nf.format(amount))
}


export const FormatOnly = (amount) => {
  var nf = new Intl.NumberFormat();
  return (nf.format(amount))
}



export const customModalStyles = {
      content: {
            position: 'absolute',
            bottom: 0,
            border: '0px solid #ccc',
            background: footer,
            borderRadius: '30px 30px 0 0',
            outline: 'none',
            padding: '20px',
            color: '#fff',
            marginBottom: 0,
            width: '100%',
            transition: '0.5s',
      },
      overlay: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99999999,
        backgroundColor: 'rgba(0, 0, 0, 0.6)',
        padding: 0,
        margin: 0,
      }
    };



export const customNormalModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    width: '100%',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'transparent',
    zIndex: 999999,
    
  },

  overlay: {
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 99999999,
        backgroundColor: otherColors.cotpBlue,
        padding: 0,
        margin: 0,
      }
};




export const balanceSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,

        responsive: [{
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1
            }
        }]
    };




export const currencySliderArray = (refToUse, financeArray) => {
    let arrowd;

    arrowd = financeArray?.length > 1 && <div>
        <BsArrowLeftSquare onClick={() => refToUse.current.slickPrev()} className="cursor-pointer" size={20}/> {" "}
        <BsArrowRightSquare onClick={() => refToUse.current.slickNext()} className="cursor-pointer" size={20}/>
    </div>

    return arrowd;
}


  export const moneyStatus = () => {
    return ['Pending', 'Processing', 'Confirmed', 'Cancelled'];
  }


export const usefulColors = (code) => {
    let array = ['danger', 'success', 'info', 'warning'];
    return array[code] ?? 'warning';
}

export const sumFieldInArray = (arrayOfObj, objFileName) => {
  if(!arrayOfObj){ return 0; }
  return arrayOfObj?.reduce((accumulators, item) => { return (Number(item?.[objFileName]) + accumulators)}, 0);
}

export const capitalizeFirstLetter =(stro) => {
  if(!stro){ return ''; }
  let str = String(stro)
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)
}


export const supportedGamesEngines = () => {
  return [
      { name: '30', timeTitle: 'Seconds', titleShort: 'Sec.', seconds: 30 },
      { name: '1', timeTitle: 'Minute', titleShort: 'Min.', seconds: 60 },
      { name: '3', timeTitle: 'Minutes', titleShort: 'Min.', seconds: 180 },
      { name: '5', timeTitle: 'Minutes', titleShort: 'Min.', seconds: 300 },
  ];
};


// export const calculateWinningMultiplier = (userInput, result) => {

//   // Define winning conditions
//   const greenWins = [1, 3, 7, 9];
//   const redWins = [2, 4, 6, 8];
//   const violetWins = [0, 5];
//   const bigWins = [5, 6, 7, 8, 9];
//   const smallWins = [0, 1, 2, 3, 4];

//   let multiplier = 0;
//   let roWinningNumber = null

//   const winningSize = bigWins.includes(result) ? 'big' : 'small';
//   const winningColor = greenWins.includes(result)
//       ? 'green'
//       : redWins.includes(result)
//       ? 'red'
//       : 'violet';

//   // Determine multiplier based on input
//   switch (userInput) {
//       case 'green':
//           if (greenWins.includes(result)) {
//               multiplier = 2;
//               // roWinningNumber = greenWins?.[0]
//           } else if (result === 5) {
//               multiplier = 1.5;
//               // roWinningNumber = 5
//           }
//           break;

//       case 'red':
//           if (redWins.includes(result)) {
//               multiplier = 2;
//           } else if (result === 0) {
//               multiplier = 1.5;
//           }
//           break;

//       case 'violet':
//           if (violetWins.includes(result)) {
//               multiplier = 2;
//           }
//           break;

//       case 'big':
//           if (bigWins.includes(result)) {
//               multiplier = 2;
//           }
//           break;

//       case 'small':
//           if (smallWins.includes(result)) {
//               multiplier = 2;
//           }
//           break;

//       default:
//           // Check if input is a valid number
//           if (userInput === result) {
//               multiplier = 9;
//           }
//           break;
//   }

//   let winningColors = [winningColor];

//   if (result === 0) {
//       winningColors = ['red', 'violet'];
//   } else if (result === 5) {
//       winningColors = ['green', 'violet'];
//   }

//   // No win condition met
//   return {
//       multiplier: multiplier,
//       winningNumber: [result],
//       winningSize: [winningSize],
//       winningColor: winningColors,
//       winningColorMashed: winningColors?.join('')
//   };
// }



export const calculateWinningMultiplier = (userInput, resultRaw = null) => {
  // Define winning conditions
  const greenWins = [1, 3, 7, 9];
  const redWins = [2, 4, 6, 8];
  const violetWins = [0, 5];
  const bigWins = [5, 6, 7, 8, 9];
  const smallWins = [0, 1, 2, 3, 4];

  let multiplier = 0;
  let potentialResults = [];
  let winningColors = [];
  let winningSize = null;

  const result = Number(resultRaw)
  
  if (resultRaw !== null) {
    // Result is provided - calculate based on actual outcome
    winningSize = bigWins.includes(result) ? 'big' : 'small';
    const winningColor = greenWins.includes(result)
      ? 'green'
      : redWins.includes(result)
      ? 'red'
      : 'violet';

    // Determine multiplier based on input
    switch (userInput) {
      case 'green':
        multiplier = greenWins.includes(result) ? 2 : result === 5 ? 1.5 : 0;
        break;

      case 'red':
        multiplier = redWins.includes(result) ? 2 : result === 0 ? 1.5 : 0;
        break;

      case 'violet':
        multiplier = violetWins.includes(result) ? 2 : 0;
        break;

      case 'big':
        multiplier = bigWins.includes(result) ? 2 : 0;
        break;

      case 'small':
        multiplier = smallWins.includes(result) ? 2 : 0;
        break;

      default:
        // Direct match with result
        multiplier = Number(userInput) == Number(result) ? 9 : 0;
        break;
    }

    // Define the winning colors for the given result
    winningColors = result == 0 
      ? ['red', 'violet'] 
      : result == 5 
      ? ['green', 'violet'] 
      : [winningColor];

    return {
      multiplier,
      winningNumber: [result],
      winningSize: [winningSize],
      winningColor: winningColors,
      winningColorMashed: winningColors.join(''),
    };
  } else {
    // No result provided - calculate potential multipliers
    switch (userInput) {
      case 'green':
        potentialResults = [...greenWins, 5]; // Green wins + special case
        multiplier = 2; // Highest multiplier for green
        break;

      case 'red':
        potentialResults = [...redWins, 0]; // Red wins + special case
        multiplier = 2;
        break;

      case 'violet':
        potentialResults = [...violetWins];
        multiplier = 2;
        break;

      case 'big':
        potentialResults = [...bigWins];
        multiplier = 2;
        break;

      case 'small':
        potentialResults = [...smallWins];
        multiplier = 2;
        break;

      default:
        if (!isNaN(Number(userInput))) {
          // User input is a number
          potentialResults = [Number(userInput)];
          multiplier = 9;
        }
        break;
    }

    // Determine potential winning colors for all possible results
    winningColors = potentialResults.map((res) =>
      greenWins.includes(res)
        ? 'green'
        : redWins.includes(res)
        ? 'red'
        : 'violet'
    );

    if(userInput == 0){
      winningColors = ['red', 'violet']
    }else if(userInput == 5){
      winningColors = ['green', 'violet']
    }else if(userInput == 'green'){
      winningColors = ['green']
    }else if(userInput == 'red'){
      winningColors = ['red']
    }else if(userInput == 'violet'){
      winningColors = ['violet']
    }else if(userInput == 'big'){
      winningColors = ['#ff9800']
    }else if(userInput == 'small'){
      winningColors = ['#2196f3']
    }

    

    return {
      potentialResults,
      multiplier,
      winningColors: Array.from(new Set(winningColors)), // Unique colors
      potentialSizes: [
        ...new Set(
          potentialResults.map((res) =>
            bigWins.includes(res) ? 'big' : 'small'
          )
        ),
      ],
    };
  }
};


// const getGameColor = (userInput) => {
//   const greenWins = [1, 3, 7, 9];
//   const redWins = [2, 4, 6, 8];
//   // const violetWins = [0, 5];
//   const bigWins = [5, 6, 7, 8, 9];
//   const smallWins = [0, 1, 2, 3, 4];

//   if(userInput == 0){
//       winningColors = ['red', 'violet']
//     }else if(userInput == 5){
//       winningColors = ['green', 'violet']
//     }else if(userInput == 'green'){
//       winningColors = ['green']
//     }else if(userInput == 'red'){
//       winningColors = ['red']
//     }else if(userInput == 'violet'){
//       winningColors = ['violet']
//     }else if(userInput == 'big'){
//       winningColors = ['#ff9800']
//     }else if(userInput == 'small'){
//       winningColors = ['#2196f3']
//   }

//   if(greenWins.includes(userInput)){
//     winningColors = {colorName: ['green']}
//   }else if(redWins.includes(userInput)){
//     winningColors = {colorName: ['red']}
//   }else if(violetWins.includes(userInput)){
//     winningColors = {colorName: ['violet']}
//   }


// }


export default Helper1;
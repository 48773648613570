import React, { useState } from 'react';
import { 
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Paper,
  Container,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate, useHistory, useLocation } from 'react-router-dom';

import {mainStyle, mainColor, header, footer} from '../../utility/styles';
import {menuItems} from '../../utility/menuLists';

// Styled components
const StyledBottomNavigation = styled(BottomNavigation)(({ theme }) => ({
  backgroundColor: footer || theme.palette.primary.main,
  borderTop: `1px solid ${theme.palette.divider}`,
  '& .MuiBottomNavigationAction-root': {
    color: '#cecece',
    minWidth: 'auto',
    padding: '6px 12px',
    '&.Mui-selected': {
      color: '#ffffff',
    },
    '& .MuiBottomNavigationAction-label': {
      fontSize: '0.75rem',
      '&.Mui-selected': {
        fontSize: '0.75rem',
      },
    },
  },
}));

const NavContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: theme.zIndex.appBar,
  '& > *': {
    margin: '0 auto',
  },
}));

const Footer = () => {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Get current value from location
  const getCurrentValue = () => {
    return menuItems.findIndex(item => item.address === location.pathname);
  };

  const handleChange = (event, newValue) => {
    history.push(menuItems[newValue].address);
  };

  return (
    <NavContainer>
      <Container 
        maxWidth="sm" 
        disableGutters={isMobile}
        sx={{
          '& > *': {
            borderTopLeftRadius: isMobile ? 0 : 0,
            borderTopRightRadius: isMobile ? 0 : 0,
            overflow: 'hidden',
          },
        }}

        style={{padding: 0}}
      >
        <Paper elevation={3}>
          <StyledBottomNavigation
            value={getCurrentValue()}
            onChange={handleChange}
            showLabels
          >
            {menuItems.map((item, index) => {
              const MenuIcon = item.icon;
              return (
                <BottomNavigationAction
                  key={index}
                  label={item.title}
                  icon={
                    <MenuIcon 
                      size={24}
                      style={{
                        transition: 'all 0.2s',
                        transform: getCurrentValue() === index ? 'scale(1.1)' : 'scale(1)',
                      }}
                    />
                  }
                  sx={{
                    '&::after': {
                      content: '""',
                      position: 'absolute',
                      bottom: 0,
                      left: '50%',
                      transform: 'translateX(-50%)',
                      width: getCurrentValue() === index ? '40%' : '0%',
                      height: 3,
                      bgcolor: 'common.white',
                      borderRadius: '4px 4px 0 0',
                      transition: 'all 0.3s',
                    },
                  }}
                />
              );
            })}
          </StyledBottomNavigation>
        </Paper>
      </Container>
    </NavContainer>
  );
};

export default Footer;
import * as actionTypes from "./actionTypes";

export const loadAllGames = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.GAMES_LOAD_ALL,
    callback,
  };
}


export const loadUserGames = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.GAMES_LOAD_USERS,
    callback,
  };
}


export const loadPlayedHistory = (routeToCall, callback = () => {}) => {
  return {
    routeToCall,
    type: actionTypes.GAMES_LOAD_HISTORY,
    callback,
  };
}


export const onBetNewGame = (param, routeToCall, callback = () => {}) => {
  return {
    param,
    routeToCall,
    type: actionTypes.GAMES_BET_NEW,
    callback,
  };
};
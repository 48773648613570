import * as actionTypes from "../actions/actionTypes";

const initialState = {
  allGames: null,
  userGames: null,
  gameHistory: null
}

export default (state = initialState, action = {}) => {
  switch (action.type) {

    case actionTypes.GAMES_SAVE_ALL:
      return {
        ...state,
        allGames: action.data,
      };


    // Games success
    case actionTypes.GAMES_SAVE_USERS:
      return {
        ...state,
        userGames: action.data,
      };

    case actionTypes.GAMES_SAVE_HISTORY:
      return {
        ...state,
        gameHistory: action.data,
      };

    default:
      return state;
  }
};

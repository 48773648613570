import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Card,
  CardContent,
  MenuItem,
  InputAdornment,
  Alert,
  Snackbar,
  CircularProgress,
  Chip,
  Grid
} from '@mui/material';
import {
  CreditCard as CreditCardIcon,
  AccountBalance as BankIcon,
  Person as PersonIcon,
  Numbers as NumbersIcon,
  Lock as LockIcon
} from '@mui/icons-material';

import { authActions } from '../redux/actions';
import { userSelect, allRemoteSettingsSelect } from '../redux/selectors';

import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";


const BankCardBinding = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userSelect);
  const site_settings = useSelector(allRemoteSettingsSelect);

  // Form state
  const [formData, setFormData] = useState({
    bank: userData?.bank || '',
    accountName: userData?.accountName || '',
    accountNumber: userData?.accountNumber || '',
    pin: ''
  });

  // UI state
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Form validation
  const [errors, setErrors] = useState({
    accountName: '',
    accountNumber: '',
    pin: ''
  });

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    setFormData(prev => ({ ...prev, [field]: value }));
    
    // Validation
    if (field === 'accountNumber') {
      setErrors(prev => ({
        ...prev,
        accountNumber: /^\d{10,}$/.test(value) ? '' : 'Please enter a valid account number'
      }));
    } else if (field === 'pin') {
      setErrors(prev => ({
        ...prev,
        pin: value.length === 6 ? '' : 'PIN must be 6 digits'
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (Object.values(errors).some(error => error)) return;
    
    setLoading(true);
    
    const updateObject = {
      bank: formData.bank,
      accountName: formData.accountName,
      accountNumber: formData.accountNumber,
      pin: formData.pin
    };

    try {
      dispatch(authActions.onLogin(
        'account/account_change/0',
        updateObject,
        response => {
          setSnackbar({
            open: true,
            message: response.msg,
            severity: response.code === 1 ? 'success' : 'error'
          });
          
          if (response.code === 1) {
            setFormData(prev => ({ ...prev, pin: '' }));
          }
          
          setLoading(false);
        }
      ));
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'An error occurred. Please try again.',
        severity: 'error'
      });
      setLoading(false);
    }
  };

  return (<SiteWide>
        <Section overlayText={'Loading...'} loading={false} title={'Bind Card'}>
            <Container maxWidth="md">
            <Paper elevation={0} sx={{ p: 4, mt: 4 }}>

                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <TextField
                    select
                    fullWidth
                    margin="normal"
                    label="Select Provider"
                    value={formData.bank}
                    onChange={handleInputChange('bank')}
                    required
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <BankIcon color="primary" />
                        </InputAdornment>
                    )
                    }}
                >
                    <MenuItem value="" disabled>Select Provider</MenuItem>
                    {site_settings?.banks?.map((item, index) => (
                    <MenuItem key={index} value={item.code}>
                        {item.name}
                    </MenuItem>
                    ))}
                </TextField>

                <TextField
                    margin="normal"
                    fullWidth
                    label="Account Name"
                    value={formData.accountName}
                    onChange={handleInputChange('accountName')}
                    required
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <PersonIcon color="primary" />
                        </InputAdornment>
                    )
                    }}
                />

                <TextField
                    margin="normal"
                    fullWidth
                    label="Account Number"
                    value={formData.accountNumber}
                    onChange={handleInputChange('accountNumber')}
                    error={!!errors.accountNumber}
                    helperText={errors.accountNumber}
                    required
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <NumbersIcon color="primary" />
                        </InputAdornment>
                    )
                    }}
                />

                <TextField
                    margin="normal"
                    fullWidth
                    label="PIN"
                    type="password"
                    value={formData.pin}
                    onChange={handleInputChange('pin')}
                    error={!!errors.pin}
                    helperText={errors.pin}
                    required
                    inputProps={{ maxLength: 6 }}
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <LockIcon color="primary" />
                        </InputAdornment>
                    )
                    }}
                />

                <Box sx={{ mt: 3, mb: 2 }}>
                    <Chip 
                    label={`Total card bind left: ${userData?.walletUpdateChanceFiat}`}
                    color="primary"
                    variant="outlined"
                    />
                </Box>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    disabled={loading || Object.values(errors).some(error => error)}
                    sx={{ mt: 3, mb: 2 }}
                >
                    {loading ? (
                    <CircularProgress size={24} color="inherit" />
                    ) : (
                    'Bind Card'
                    )}
                </Button>
                </Box>
            </Paper>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            >
                <Alert
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                severity={snackbar.severity}
                variant="filled"
                >
                {snackbar.message}
                </Alert>
            </Snackbar>
            </Container>
        </Section>
    </SiteWide>
  );
};

export default BankCardBinding;
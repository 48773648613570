import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  TextField,
  Button,
  Paper,
  Typography,
  Container,
  InputAdornment,
  CircularProgress
} from '@mui/material';
import { authActions } from '../../redux/actions';
import { 
  userSelect, 
  allRemoteSettingsSelect, 
  homeScreenStatSelect 
} from '../../redux/selectors';
import { notify } from '../../utility/Helper1';

import Section from "../layouts/Section";
import {WebLink, SiteWide} from "../customComponents";
import {
  Phone as PhoneIcon,
  Public as PublicIcon,
  Lock as LockIcon
} from '@mui/icons-material';

const ChangePassword = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userSelect);
  const siteSettings = useSelector(allRemoteSettingsSelect);
  const finance = useSelector(homeScreenStatSelect);

  const [pin, setPin] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [securityObj, setSecurityObj] = useState({});

  const handlePasswordChange = async (e) => {
    e.preventDefault();
    
    if (!password.trim()) {
      notify('Please input new password', 0);
      return;
    }

    const data = { password, pin };
    setLoading(true);

    dispatch(authActions.onLogin(
      "account/change_password",
      data,
      (response) => {
        if (response.code === 1) {
          setSecurityObj({});
          setPin('')
          setPassword('')
        }
        notify(response.msg, response.code);
        setLoading(false);
      }
    ));
  };

  return (<SiteWide>
        <Section overlayText={'Loading...'} loading={false} title={'Change Password'}>
          <Container maxWidth="sm">
            <Paper 
              elevation={0} 
              sx={{ 
                p: 4, 
                mt: 4,
                backgroundColor: theme => theme.palette.background.paper
              }}
            >

              <Box component="form" onSubmit={handlePasswordChange} sx={{ mt: 2 }}>
                <TextField
                  fullWidth
                  label="New Password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  margin="normal"
                  variant="outlined"
                  sx={{ mb: 3 }}
                />

                <TextField
                  margin="normal"
                  fullWidth
                  label="PIN"
                  type="password"
                  value={pin}
                  onChange={e => setPin(e.target.value)}
                  required
                  inputProps={{ maxLength: 6 }}
                  InputProps={{
                  startAdornment: (
                      <InputAdornment position="start">
                      <LockIcon color="primary" />
                      </InputAdornment>
                  )
                  }}
                />


                <Button
                  type="submit"
                  variant="contained"
                  fullWidth
                  disabled={loading}
                  sx={{ 
                    height: 48,
                    position: 'relative'
                  }}
                >
                  {loading ? (
                    <CircularProgress 
                      size={24} 
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px'
                      }}
                    />
                  ) : 'Change Password'}
                </Button>
              </Box>
            </Paper>
          </Container>
        </Section>
      </SiteWide>
  );
};

export default ChangePassword;
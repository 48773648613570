import React, { Component, useEffect } from "react";
import {Router, Route, Switch, HashRouter} from "react-router-dom";

import UnverifiedMailRoute from './utility/UnverifiedMailRoute';
import PrivateRoute from './utility/PrivateRoute';
import PublicRoute from './utility/PublicRoute';


import history from "./History";
import Home from "./components/Home";
import About from "./components/About";
import News from "./components/News";
import Faq from "./components/Faq";
import Term from "./components/Term";
import Social from "./components/Social";
import Salary from "./components/Salary";
import Agent from "./components/Agent";
import Map from "./components/Map";
import Swap from "./components/Swap";
import Bonus from "./components/Bonus";
import Bank from "./components/Bank";
import Wallet from "./components/Wallet";
import Phone from "./components/Phone";

import ReinvestBonus from "./components/ReinvestBonus";
import gameHome from "./components/gameHome";

import FX from "./components/FX";


import AllPages from "./components/AllPages";
import BillPay from "./components/BillPay";
import Blog from "./components/Blog";
import BlogDetails from "./components/BlogDetails";
import Carts from "./components/Carts";
import Components from "./components/Components";
import ComponentsAccordion from "./components/ComponentsAccordion";
import ComponentsAlerts from "./components/ComponentsAlerts";
import ComponentsButtons from "./components/ComponentsButtons";
import ComponentsModals from "./components/ComponentsModals";
import ComponentsNotifications from "./components/ComponentsNotifications";
import ComponentsProgress from "./components/ComponentsProgress";
import ComponentsTabs from "./components/ComponentsTabs";
// import ComponentsTooltip from "./components/ComponentsTooltip";
import Contact from "./components/Contact";
import Notification from "./components/Notification";
import Saving from "./components/Saving";
import Signin from "./components/Signin";
import Signup from "./components/Signup";
import VerifyEmail from './components/VerifyEmail';
import ForgetPassword from './components/ForgetPassword';


import Transaction from "./components/Transaction";
import TransactionDetails from "./components/TransactionDetails";
import PersonalInformation from "./components/PersonalInformation";
import ChangePassword from "./components/PersonalInformation/ChangePassword";
import ChangePin from "./components/PersonalInformation/ChangePin";

import Trade from "./components/Trade";
import Bot from "./components/Bot";

import Arbitrage from "./components/Arbitrage";
import Asset from "./components/Asset";
import P2P from "./components/P2P";
import AllP2P from "./components/AllP2P";
import Chats from "./components/Chats";
import KYC from "./components/KYC";
import AllKYC from "./components/AllKYC";
import {Images} from "./components/customComponents";


import Testimony from "./components/Testimony";


import TradeHistory from "./components/Trade-history";
import Referrals from "./components/Referrals";
import TeamEarning from "./components/TeamEarning";


import Deposit from "./components/finance/Deposit";
import DepositHistory from "./components/Deposit-history";
import WithdrawalHistory from "./components/Withdrawal-history";


import UserVerification from "./components/UserVerification";
import { useSelector, useDispatch } from 'react-redux';
import {authActions} from './redux/actions';
import { userSelect, allRemoteSettingsSelect } from './redux/selectors/index';
import LoadingOverlay from 'react-loading-overlay';
import Loading from "./components/layouts/Loading";

    


function Routes(){

    
    const dispatch = useDispatch();
    const userData = useSelector(userSelect);
    const [loading, setLoading] = React.useState(true);


    useEffect(() => {
        dispatch(authActions.onRefreshSystemData("system/refresh", 10, {}, response => {
            setLoading(false)
        }))
    }, [userData?.email])


    return (<>
        
            <Loading loading={loading} >
                <Router history={history}>
                    <HashRouter basename="/">
                        <Switch>
                            <Route exact path={'/all-pages'} render={(props) => (<AllPages {...props} />)} />
                            <Route exact path={'/bill-pay'} render={(props) => (<BillPay {...props} />)} />
                            <Route exact path={'/blog'} render={(props) => (<Blog {...props} />)} />
                            <Route exact path={'/blog-details'} render={(props) => (<BlogDetails {...props} />)} />
                            <Route exact path={'/carts'} render={(props) => (<Carts {...props} />)} />
                            <Route exact path={'/components'} render={(props) => (<Components {...props} />)} />
                            <Route exact path={'/components-accordion'} render={(props) => (<ComponentsAccordion {...props} />)} />
                            <Route exact path={'/components-alerts'} render={(props) => (<ComponentsAlerts {...props} />)} />
                            <Route exact path={'/components-buttons'} render={(props) => (<ComponentsButtons {...props} />)} />
                            <Route exact path={'/components-modal'} render={(props) => (<ComponentsModals {...props} />)} />
                            <Route exact path={'/components-notifications'} render={(props) => (<ComponentsNotifications {...props} />)} />
                            <Route exact path={'/components-progress'} render={(props) => (<ComponentsProgress {...props} />)} />
                            <Route exact path={'/components-tabs'} render={(props) => (<ComponentsTabs {...props} />)} />
                            {/*<Route exact path={'/components-tooltip'} render={(props) => (<ComponentsTooltip {...props} />)} />*/}
                            <Route exact path={'/contact'} render={(props) => (<Contact {...props} />)} />
                            <Route exact path={'/notification'} render={(props) => (<Notification {...props} />)} />
                            <Route exact path={'/saving'} render={(props) => (<Saving {...props} />)} />
                            

                            <Route exact path={'/'} component={Home} />
                            <Route exact path={'/about'} component={About} />
                            <Route exact path={'/news'} component={News} />

                            <Route exact path={'/faq'} component={Faq} />
                            <Route exact path={'/term'} component={Term} />


                            

                            <PublicRoute exact path={'/signin'} component={Signin} />
                            <PublicRoute exact path={'/signup'} component={Signup} />
                            <PublicRoute exact path={'/reset-password'} component={ForgetPassword} />


                            <UnverifiedMailRoute exact path={'/verify-email'} component={VerifyEmail} />
                            <UnverifiedMailRoute exact path={'/user-verification'} component={UserVerification} />

                            <Route exact path={'/transaction'} render={(props) => (<Transaction {...props} />)} />
                            <Route exact path={'/transaction-details'} render={(props) => (<TransactionDetails {...props} />)} />
                            

                            <PrivateRoute exact path={'/personal-information'} component={PersonalInformation} />
                            <PrivateRoute exact path={'/change-password'} component={ChangePassword} />
                            <PrivateRoute exact path={'/change-pin'} component={ChangePin} />
                            
                            <PrivateRoute exact path={'/feedback'} component={Testimony} />
                            <PrivateRoute exact path={'/bot'} component={Bot} />

                            <PrivateRoute exact path={'/social'} component={Social} />
                            <PrivateRoute exact path={'/global'} component={Arbitrage} />
                            <PrivateRoute exact path={'/asset'} component={Asset} />
                            <PrivateRoute exact path={'/chat'} component={Chats} />
                            <PrivateRoute exact path={'/kyc'} component={KYC} />
                            <PrivateRoute exact path={'/all-kyc'} component={AllKYC} />

                            <PrivateRoute exact path={'/trade-record'} component={TradeHistory} />
                            <PrivateRoute exact path={'/deposit'} component={Deposit} />
                            <PrivateRoute exact path={'/deposit-records'} component={DepositHistory} />
                            <PrivateRoute exact path={'/withdrawal-records'} component={WithdrawalHistory} />
                            <PrivateRoute exact path={'/team'} component={Referrals} />
                            <PrivateRoute exact path={'/agency-center'} component={TeamEarning} />
                            <PrivateRoute exact path={'/salary'} component={Salary} />
                            <PrivateRoute exact path={'/agent'} component={Agent} />
                            <PrivateRoute exact path={'/address'} component={Map} />
                            <PrivateRoute exact path={'/p2p'} component={P2P} />
                            <PrivateRoute exact path={'/swap'} component={Swap} />
                            <PrivateRoute exact path={'/bank'} component={Bank} />
                            <PrivateRoute exact path={'/wallet'} component={Wallet} />
                            <PrivateRoute exact path={'/phone'} component={Phone} />

                            
                            <PrivateRoute exact path={'/reinvest-bonus'} component={ReinvestBonus} />
                            <PrivateRoute exact path={'/bonus'} component={Bonus} />
                            <PrivateRoute exact path={'/game-home'} component={gameHome} />

                            
                            {/*<PrivateRoute exact path={'/network'} component={(props) => (<Referrals {...props} />)} />*/}


                        </Switch>
                    </HashRouter>
                </Router>
            </Loading>
        </>
    )

}

export default Routes;

import React from 'react';
import { Box, CircularProgress, Fade } from '@mui/material';

const Loading = ({ loading = false, children }) => {
  return (
    <Box position="relative">
      {children}
      
      <Fade in={loading} timeout={800}>
        <Box
          sx={{
            display: loading ? 'flex' : 'none',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            alignItems: 'center',
            justifyContent: 'center',
            bgcolor: 'rgba(255, 255, 255, 0.8)',
            backdropFilter: 'blur(4px)',
            borderRadius: 'inherit',
            zIndex: 100,
          }}
        >
          <CircularProgress
            size={40}
            thickness={4}
            sx={{
              color: 'primary.main',
              animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite',
              '@keyframes pulse': {
                '0%, 100%': {
                  opacity: 1,
                },
                '50%': {
                  opacity: 0.5,
                },
              },
            }}
          />
        </Box>
      </Fade>
    </Box>
  );
};

export default Loading;
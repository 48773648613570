import { combineReducers } from "redux";
import authReducer from "./auth";
import fxReducer from "./fx";
import depositReducer from "./deposit";
import withdrawalReducer from "./withdrawal";
import financeReducer from "./finance";
import all_remote_settingsReducer from "./all_remote_settings";
import tradeReducers from "./trade";
import notificationReducers from "./notification";
import exchangeReducers from "./exchange";
import gamesReducers from "./games";


import * as actionTypes from "../actions/actionTypes";
import storage from 'redux-persist/lib/storage'

const appReducer = combineReducers({
  
  auth: authReducer,
  finance: financeReducer,
  settings: all_remote_settingsReducer,
  fx: fxReducer,
  deposit: depositReducer,
  withdrawal: withdrawalReducer,
  trade: tradeReducers,
  notify: notificationReducers,
  exchanger: exchangeReducers,
  games: gamesReducers
  
});

export const rootReducer = (state, action) => {
  if (action.type === actionTypes.LOGOUT) {
    storage.removeItem('persist:root')
    return appReducer(undefined, action)
  }

  return appReducer(state, action)
}
export const LOGIN_SUCCESS 	= "LOGIN_SUCCESS";
export const LOGIN 			= "LOGIN";
export const LOGOUT 		= "LOGOUT";
export const SIGN_UP 		= "SIGN_UP";
export const CLEAR_USER_DATA 		= "CLEAN_USER_DATA";
export const UPDATE_USER = "UPDATE_USER";

export const LOAD_HOMEPAGE_STATS = "LOAD_HOMEPAGE_STATS";

export const TOKEN 			= "TOKEN";
export const UPDATE_TOKEN = "UPDATE_TOKEN";


export const HOME_SCREEN_STAT 		= "HOME_SCREEN_STAT";
export const CLEAR_HOME_SCREEN_STAT 		= "CLEAR_SCREEN_STAT";
export const CLEAR_ALL_REDUCERS_DATA 		= "CLEAR_ALL_REDUCERS_DATA";

export const RELOAD_SYSTEM_DATA 		= "RELOAD_ACCOUNT_DATA";
export const RELOAD_SYSTEM_DATA_POST 		= "RELOAD_SYSTEM_DATA_POST";


export const RESEND_VERIFY_EMAIL 		= "RESEND_VERIFY_EMAIL";
export const VERIFY_EMAIL 		= "VERIFY_EMAIL";


export const SAVE_REMOTE_SITE_SETTINGS 		= "SAVE_REMOTE_SITE_SETTINGS";
export const SAVE_TICKER_HOME 			= "SAVE_TICKER_HOME";

export const FETCH_FX_HEAD 		= "FETCH_FX_HEAD";
export const SAVE_FX_HEAD 		= "SAVE_FX_HEAD";



export const TRADE_POST 		= "TRADE_POST";
export const TRADE_GET 			= "TRADE_GET";
export const TRADE_SAVE 		= "TRADE_SAVE";


export const EXCHANGE_POST 		= "EXCHANGE_POST";
export const EXCHANGE_GET 			= "EXCHANGE_GET";
export const EXCHANGE_SAVE 		= "EXCHANGE_SAVE";


export const SAVE_DEPOSIT_DATA 		= "SAVE_DEPOSIT_DATA";
export const MANAGE_DEPOSIT 		= "MANAGE_DEPOSIT";
export const ALL_DEPOSIT 			= "ALL_DEPOSIT";


export const SAVE_WITHDRAWAL_DATA 		= "SAVE_WITHDRAWAL_DATA";
export const MANAGE_WITHDRAWAL 			= "MANAGE_WITHDRAWAL";
export const ALL_WITHDRAWALS 			= "ALL_WITHDRAWALS";

export const MANAGE_NOTIFICATION 			= "MANAGE_NOTIFICATION";
export const CHANGE_NOTIFICATION_NOW 			= "CHANGE_NOTIFICATION_NOW";




export const GAMES_LOAD_ALL 			= "GAMES_LOAD_ALL";
export const GAMES_SAVE_ALL 				= "GAMES_SAVE_ALL";
export const GAMES_LOAD_USERS 			= "GAMES_LOAD_USERS";
export const GAMES_SAVE_USERS 			= "GAMES_SAVE_USERS";
export const GAMES_BET_NEW 				= "GAMES_BET_NEW";
export const GAMES_LOAD_HISTORY 				= "GAMES_LOAD_HISTORY";
export const GAMES_SAVE_HISTORY 				= "GAMES_SAVE_HISTORY";







import React, { useState } from 'react';
import { 
  Paper, 
  Table, 
  TableBody, 
  TableCell, 
  Tooltip,
  TableContainer, 
  TableHead, 
  TableRow,
  Box,
  TablePagination,
  useTheme
} from '@mui/material';
import { getAllGameSelect, getUserHistorySelect } from '../../redux/selectors';
import { useSelector } from 'react-redux';
import { calculateWinningMultiplier } from '../../utility/Helper1';

const ResultTable = () => {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();
  // const data = useSelector(getAllGameSelect);
  const data = useSelector(getUserHistorySelect);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const getGradientStyle = (colorsArray) => {

    return {
      background: `linear-gradient(45deg, ${colorsArray?.[0]}, ${colorsArray?.[1] ?? colorsArray?.[0]})`,
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      backgroundClip: 'text',
      fontWeight: 700,
      fontSize: '1.2rem',
      display: 'inline-block',
      padding: '4px 8px',
      borderRadius: theme.shape.borderRadius,
      filter: 'drop-shadow(0 1px 2px rgba(0,0,0,0.1))'
    };
  };

  const getColorDots = (colorArray) => {

    return (
        <Box sx={{ display: 'flex', gap: 1 }}>

          {colorArray?.map(eachColo => <Box
            sx={{
              width: 10,
              height: 10,
              borderRadius: '50%',
              backgroundColor: eachColo
            }}
          />)}

        </Box>
      );
    
  };

  return (
    <Paper 
      sx={{ 
        width: '100%',
        borderRadius: 3,
        boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
        overflow: 'hidden'
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
              <TableCell style={{padding: 10}} sx={{ color: 'white', fontWeight: 600 }}>Period</TableCell>
              <TableCell style={{padding: 10}} sx={{ color: 'white', fontWeight: 600 }}>Number</TableCell>
              <TableCell style={{padding: 10}} sx={{ color: 'white', fontWeight: 600 }}>Size</TableCell>
              <TableCell style={{padding: 10}} sx={{ color: 'white', fontWeight: 600 }}>Color</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => {
                
                const bigOrSmall = calculateWinningMultiplier(row.winning_number, row.winning_number)
                const colorsArray = bigOrSmall?.['winningColor'] ?? []
                const newTxnId = `${row.hash}${row.txn_id}`

                return <TableRow 
                  key={row.hash}
                  sx={{ 
                    '&:hover': { 
                      backgroundColor: 'rgba(66, 99, 235, 0.05)',
                      transition: 'background-color 0.2s'
                    }
                  }}
                >
                  <TableCell sx={{ fontFamily: 'monospace' }}>
                    <Tooltip title={newTxnId}>
                      {newTxnId?.slice(0, 5)}...{newTxnId?.slice(-5)}
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Box sx={getGradientStyle(colorsArray)}>
                      {row.winning_number}
                    </Box>
                  </TableCell>
                  <TableCell>{bigOrSmall?.['winningSize'][0]}</TableCell>
                  <TableCell>
                    {getColorDots(colorsArray)}
                  </TableCell>
                </TableRow>
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data?.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.default
        }}
      />
    </Paper>
  );
};

export default ResultTable;
import React from 'react';
import { Box, Typography, keyframes } from '@mui/material';
import { Casino, Stars } from '@mui/icons-material';

// Animation keyframes
const shimmer = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const float = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const glowPulse = keyframes`
  0%, 100% {
    text-shadow: 0 0 10px rgba(66, 220, 255, 0.3);
  }
  50% {
    text-shadow: 0 0 20px rgba(66, 220, 255, 0.6);
  }
`;

const HeadlineComponent = ({
  title = "Gaming Hub",
  subtitle = "Choose your next adventure",
  accentColor = "#42DCFF"
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        p: 4,
        mb: 4,
        marginTop: 2,
        background: `linear-gradient(135deg, #372d47 0%, #241839 100%)`,
        borderRadius: '20px',
        overflow: 'hidden'
      }}
    >
      {/* Animated background accent */}
      {/* <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          height: '4px',
          background: `linear-gradient(90deg, 
            transparent 0%, 
            ${accentColor} 50%, 
            transparent 100%)`,
          animation: `${shimmer} 3s infinite`,
          opacity: 0.7
        }}
      /> */}

      {/* Decorative elements */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          right: '5%',
          transform: 'translateY(-50%)',
          animation: `${float} 4s infinite ease-in-out`,
          display: { xs: 'block', md: 'block' }
        }}
      >
        <Casino
          sx={{
            fontSize: 60,
            color: accentColor,
            opacity: 0.2
          }}
        />
      </Box>

      <Box
        sx={{
          position: 'absolute',
          top: '20%',
          right: '15%',
          animation: `${float} 3s infinite ease-in-out`,
          animationDelay: '1s',
          display: { xs: 'block', md: 'block' }
        }}
      >
        <Stars
          sx={{
            fontSize: 40,
            color: accentColor,
            opacity: 0.15
          }}
        />
      </Box>

      {/* Main content */}
      <Box sx={{ position: 'relative', zIndex: 2 }}>
        <Typography
          variant="h6"
          sx={{
            fontWeight: 700,
            color: '#fff',
            mb: 1,
            animation: `${glowPulse} 3s infinite`,
            textTransform: 'uppercase',
            letterSpacing: '0.05em',
            display: 'flex',
            alignItems: 'center',
            gap: 2
          }}
        >
          {title}
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{
            color: 'rgba(255,255,255,0.3)',
            maxWidth: '600px',
            position: 'relative',
            pl: 3,
            '&::before': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: '50%',
              width: '20px',
              height: '2px',
              background: accentColor,
              transform: 'translateY(-50%)'
            }
          }}
        >
          {subtitle}
        </Typography>

        {/* Decorative bottom line */}
        <Box
          sx={{
            position: 'absolute',
            bottom: -32,
            left: '5%',
            width: '90%',
            height: '1px',
            background: `linear-gradient(90deg, 
              transparent 0%, 
              rgba(255,255,255,0.1) 50%, 
              transparent 100%)`
          }}
        />
      </Box>
    </Box>
  );
};

export default HeadlineComponent;
import React, { useState, useEffect } from 'react';
import { 
  Box, Card, Typography, Button, IconButton, 
  Table, TableBody, TableCell, TableContainer, 
  TableHead, TableRow, Paper, Tabs, Tab,
  Chip, CircularProgress, Tooltip
} from '@mui/material';
import { 
  ContentCopy, Person, AccountTree,
  TrendingUp, ArrowUpward
} from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors';
import { money } from '../utility/Helper1';
import { SiteWide } from './customComponents';
import Section from "./layouts/Section";

const Referrals = () => {
  const [loading, setLoading] = useState(false);
  const [viewingLevel, setViewingLevel] = useState(1);
  const [refData, setRefData] = useState([]);
  const [mainUplineData, setMainUplineData] = useState({});

  const site_settings = useSelector(allRemoteSettingsSelect);
  const finance = useSelector(homeScreenStatSelect);
  const userData = useSelector(userSelect);
  const dispatch = useDispatch();

  const loadDownLines = (myrefid) => {
    setLoading(true);
    dispatch(authActions.onRefreshSystemData(`user/referral/${myrefid}`, 10, {}, response => {
      setLoading(false);
      setRefData(response?.data?.referrals);
      setMainUplineData(response?.data?.upline);
    }));
  };

  useEffect(() => {
    loadDownLines(userData?.myrefid);
  }, []);

  const processRefWithdrawal = () => {
    setLoading(true);
    dispatch(authActions.onLogin(`withdraw/ref`, { currency: finance?.[0]?.type }, response => {
      setLoading(false);
    }));
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (<SiteWide title="Go global">
            <Section loading={loading} title="Commission">
                <Box sx={{ p: 3, bgcolor: '#f5f5f5', minHeight: '100vh' }}>
                <Box sx={{ maxWidth: 1200, mx: 'auto' }}>
                    {/* Commission Card */}
                    <Card sx={{ mb: 4, p: 3, position: 'relative', overflow: 'visible' }}>
                    {/* <Box sx={{ 
                        position: 'absolute', 
                        top: -20, 
                        left: 20, 
                        bgcolor: '#1976d2', 
                        color: 'white',
                        px: 2,
                        py: 1,
                        borderRadius: 2
                    }}>
                        <Typography variant="subtitle2">Total Commission</Typography>
                    </Box> */}
                    
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h4">
                        {money(finance?.[0]?.commission, finance?.[0]?.type)}
                        </Typography>
                        <Button 
                        variant="contained"
                        onClick={processRefWithdrawal}
                        disabled={loading}
                        startIcon={loading ? <CircularProgress size={20} /> : <TrendingUp />}
                        >
                        Claim Rewards
                        </Button>
                    </Box>
                    </Card>

                    {/* Referral Link Card */}
                    <Card sx={{ mb: 4, p: 3 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="subtitle1">Your Referral Link</Typography>
                        <Tooltip title="Copy link">
                        <IconButton 
                            onClick={() => {
                            navigator.clipboard.writeText(`${site_settings?.site_settings?.site_url}/#/signup?ref=${userData?.myrefid}`);
                            }}
                        >
                            <ContentCopy />
                        </IconButton>
                        </Tooltip>
                    </Box>
                    <Typography 
                        variant="body2" 
                        sx={{ mt: 1, p: 2, bgcolor: '#f8f9fa', borderRadius: 1 }}
                    >
                        {`${site_settings?.site_settings?.site_url}/#/signup?ref=${userData?.myrefid}`}
                    </Typography>
                    </Card>

                    {/* Upline Info */}
                    <Card sx={{ mb: 4, p: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Person />
                        <Typography>Upline: </Typography>
                        <Chip 
                        label={mainUplineData?.username || 'None'} 
                        variant="outlined"
                        icon={<ArrowUpward />}
                        />
                    </Box>
                    </Card>

                    {/* Referral Levels & Table */}
                    <Card>
                    <Tabs 
                        value={viewingLevel - 1}
                        onChange={(_, newValue) => setViewingLevel(newValue + 1)}
                        sx={{ borderBottom: 1, borderColor: 'divider' }}
                    >
                        {[1, 2, 3, 4].map((level) => (
                        <Tab 
                            key={level}
                            label={`Level ${level}`}
                            icon={<AccountTree />}
                            iconPosition="start"
                        />
                        ))}
                    </Tabs>

                    <TableContainer component={Paper} elevation={0}>
                        <Table>
                        <TableHead>
                            <TableRow>
                            <TableCell>Username</TableCell>
                            <TableCell align="right">Signup Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {refData['level' + viewingLevel]?.map((referral, index) => (
                            <TableRow key={index}>
                                <TableCell>{referral.username}</TableCell>
                                <TableCell align="right">{formatDate(referral.date)}</TableCell>
                            </TableRow>
                            ))}
                        </TableBody>
                        </Table>
                    </TableContainer>
                    </Card>
                </Box>
                </Box>
        </Section>
    </SiteWide>
  );
};

export default Referrals;
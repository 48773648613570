import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  TextField,
  Button,
  Typography,
  Paper,
  Card,
  CardContent,
  InputAdornment,
  Alert,
  Snackbar,
  CircularProgress,
  Chip,
  Grid
} from '@mui/material';
import {
  AccountBalanceWallet as WalletIcon,
  Lock as LockIcon,
  ContentCopy as CopyIcon
} from '@mui/icons-material';

import { authActions } from '../redux/actions';
import { userSelect } from '../redux/selectors';

import Section from "./layouts/Section";
import {WebLink, SiteWide} from "./customComponents";


const WalletBinding = () => {
  const dispatch = useDispatch();
  const userData = useSelector(userSelect);

  // Form state
  const [formData, setFormData] = useState({
    wallet: userData?.trc20tetherwallet || '',
    pin: ''
  });

  // UI state
  const [loading, setLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Form validation
  const [errors, setErrors] = useState({
    wallet: '',
    pin: ''
  });

  const validateWalletAddress = (address) => {
    // Basic USDT TRC20 address validation (starts with T and is 34 characters)
    return /^T[A-Za-z0-9]{33}$/.test(address);
  };

  const handleInputChange = (field) => (event) => {
    const value = event.target.value;
    setFormData(prev => ({ ...prev, [field]: value }));
    
    // Validation
    if (field === 'wallet') {
      setErrors(prev => ({
        ...prev,
        wallet: validateWalletAddress(value) ? '' : 'Please enter a valid TRC20 wallet address'
      }));
    } else if (field === 'pin') {
      setErrors(prev => ({
        ...prev,
        pin: value.length === 6 ? '' : 'PIN must be 6 digits'
      }));
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (Object.values(errors).some(error => error)) return;
    
    setLoading(true);
    
    const updateObject = {
      wallet: formData.wallet,
      pin: formData.pin
    };

    try {
      dispatch(authActions.onLogin(
        'account/account_change/1',
        updateObject,
        response => {
          setSnackbar({
            open: true,
            message: response.msg,
            severity: response.code === 1 ? 'success' : 'error'
          });
          
          if (response.code === 1) {
            setFormData(prev => ({ ...prev, pin: '' }));
          }
          
          setLoading(false);
        }
      ));
    } catch (error) {
      setSnackbar({
        open: true,
        message: 'An error occurred. Please try again.',
        severity: 'error'
      });
      setLoading(false);
    }
  };

  return (<SiteWide>
        <Section overlayText={'Loading...'} loading={false} title={'Bind Wallet Address'}>
            <Container maxWidth="md">
            <Paper elevation={0} sx={{ p: 4, mt: 4 }}>

                <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
                <TextField
                    margin="normal"
                    fullWidth
                    label="USDT TRC20 Wallet Address"
                    value={formData.wallet}
                    onChange={handleInputChange('wallet')}
                    error={!!errors.wallet}
                    helperText={errors.wallet}
                    required
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <WalletIcon color="primary" />
                        </InputAdornment>
                    )
                    }}
                />

                <TextField
                    margin="normal"
                    fullWidth
                    label="PIN"
                    type="password"
                    value={formData.pin}
                    onChange={handleInputChange('pin')}
                    error={!!errors.pin}
                    helperText={errors.pin}
                    required
                    inputProps={{ maxLength: 6 }}
                    InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                        <LockIcon color="primary" />
                        </InputAdornment>
                    )
                    }}
                />

                <Box sx={{ mt: 3, mb: 2 }}>
                    <Chip 
                    label={`Total wallet bind left: ${userData?.walletUpdateChanceCrypto}`}
                    color="primary"
                    variant="outlined"
                    />
                </Box>

                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    size="large"
                    disabled={loading || Object.values(errors).some(error => error)}
                    sx={{ mt: 3, mb: 2 }}
                >
                    {loading ? (
                    <CircularProgress size={24} color="inherit" />
                    ) : (
                    'Bind Wallet'
                    )}
                </Button>
                </Box>
            </Paper>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            >
                <Alert
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
                severity={snackbar.severity}
                variant="filled"
                >
                {snackbar.message}
                </Alert>
            </Snackbar>
            </Container>
        </Section>
    </SiteWide>
  );
};

export default WalletBinding;
import React, {Component} from "react";
import Header from "./Header";
import Footer from "./Footer";
import Navbar from "./Navbar";
import AutoNotification from "./AutoNotification";
import {Images} from "../customComponents";

import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader'

import { 
    Box,
    Button,
    Typography,
    Container,
    Paper,
  } from '@mui/material';
import Loading from "./Loading";

function Section(props){

    return (

        <Loading loading={props.loading} >
            <section className="main-section" style={props.sectionstyle}>
                {!props.hideHeader && <Header back={props.back} title={props.title} rightTopMenu={props.rightTopMenu} home={props.home} searchPopup={props.searchPopup} />}
                {props.children}
                <br />
                <Footer />
            </section>
        </Loading>
    );

}

export default Section;
import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Stack,
  Button,
  keyframes
} from '@mui/material';
import {
  Gamepad,
  Casino,
  ArrowForward,
  Stars,
  EmojiEvents
} from '@mui/icons-material';
import { useHistory, useLocation } from 'react-router-dom';

// Keyframe animations
const shine = keyframes`
  0% {
    transform: translateX(-100%) rotate(-45deg);
  }
  100% {
    transform: translateX(200%) rotate(-45deg);
  }
`;

const float = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const pulse = keyframes`
  0%, 100% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
`;

const GameCenterBanner = ({ onGameCenterClick = () => {} }) => {

    const history = useHistory();

  return (
    <Box sx={{ p: 0 }}>
      <Card
        onClick={onGameCenterClick}
        sx={{
          position: 'relative',
          background: 'linear-gradient(135deg, #202630 0%, #171e26 100%)',
          borderRadius: 4,
          overflow: 'hidden',
          cursor: 'pointer',
          transition: 'transform 0.3s, box-shadow 0.3s',
          '&:hover': {
            transform: 'translateY(-4px)',
            boxShadow: '0 20px 40px rgba(0,0,0,0.3)',
            '& .reflection': {
              animation: `${shine} 1.5s infinite`
            }
          }
        }}
      >
        {/* Animated reflection overlay */}
        <Box
          className="reflection"
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: 'linear-gradient(45deg, transparent 30%, rgba(255,255,255,0.2) 50%, transparent 70%)',
            zIndex: 2,
            pointerEvents: 'none'
          }}
        />

        {/* Decorative background elements */}
        <Box
          sx={{
            position: 'absolute',
            top: '-20%',
            right: '-10%',
            width: '200px',
            height: '200px',
            borderRadius: '50%',
            background: 'radial-gradient(circle, rgba(123,31,162,0.4) 0%, rgba(123,31,162,0) 70%)',
            animation: `${pulse} 4s infinite ease-in-out`,
          }}
        />

        <CardContent sx={{ position: 'relative', zIndex: 3 }}>
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Stack direction="row" spacing={2} alignItems="center">
                <Box
                  sx={{
                    animation: `${float} 3s infinite ease-in-out`,
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Gamepad
                    sx={{
                      fontSize: 48,
                      color: '#FFD700',
                      filter: 'drop-shadow(0 0 10px rgba(255,215,0,0.5))'
                    }}
                  />
                </Box>
                <Stack spacing={0.5}>
                  <Typography
                    variant="h5"
                    sx={{
                      color: 'white',
                      fontWeight: 'bold',
                      textShadow: '0 2px 4px rgba(0,0,0,0.3)'
                    }}
                  >
                    Game Center
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      color: 'rgba(255,255,255,0.8)',
                      fontSize: '0.9rem'
                    }}
                  >
                    World of endless possibilities
                  </Typography>
                </Stack>
              </Stack>

              <Button
                onClick={() => history.push('/game-home')}
                variant="contained"
                endIcon={<ArrowForward />}
                sx={{
                  background: 'linear-gradient(45deg, #FFD700 30%, #FFA500 90%)',
                  color: '#1E1246',
                  fontWeight: 'bold',
                  padding: '10px 24px',
                  borderRadius: '25px',
                  boxShadow: '0 4px 15px rgba(255,215,0,0.3)',
                  '&:hover': {
                    background: 'linear-gradient(45deg, #FFA500 30%, #FFD700 90%)',
                    transform: 'scale(1.05)',
                  }
                }}
              >
                Play
              </Button>
            </Stack>

            {/* Game features section */}
            <Stack
              direction="row"
              spacing={3}
              sx={{
                mt: 2,
                pt: 2,
                borderTop: '1px solid rgba(255,255,255,0.1)'
              }}
            >
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ color: 'rgba(255,255,255,0.9)' }}
              >
                <EmojiEvents sx={{ color: '#FFD700' }} />
                <Typography variant="body2">Daily Rewards</Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ color: 'rgba(255,255,255,0.9)' }}
              >
                <Casino sx={{ color: '#FFD700' }} />
                <Typography variant="body2">Multiple Games</Typography>
              </Stack>
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                sx={{ color: 'rgba(255,255,255,0.9)' }}
              >
                <Stars sx={{ color: '#FFD700' }} />
                <Typography variant="body2">Earn Points</Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

export default GameCenterBanner;
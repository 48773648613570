import React, { useState, useEffect, useRef } from 'react';
import { 
  Box,
  Button,
  Typography,
  Container,
  Paper,
} from '@mui/material';
import { Help as HelpIcon, History as HistoryIcon, Timeline as TimelineIcon, Person as PersonIcon } from '@mui/icons-material';
import Section from "../layouts/Section";
import {WebLink, SiteWide, Images} from "../customComponents";
import TicketComponent from './TicketHeadline';
import { calculateWinningMultiplier, notify, supportedGamesEngines } from '../../utility/Helper1';
import { useDispatch, useSelector } from 'react-redux';
import { gameActions } from '../../redux/actions';
import { getAllGameSelect, getUserHistorySelect } from '../../redux/selectors';
import Loading from '../layouts/Loading';
import BettingModal from './BettingModal';
import ResultTable from './ResultTable';
import { useTimeDifference } from './useTimeDifference';
import UserHistory from './UserHistory';










const useCurrentGameTime = (gameTimeObjects) => {
  const [currentGameTime, setCurrentGameTime] = useState(null);

  // Helper function to find the current game time
  const findCurrentGameTime = () => {
    const now = new Date();
    return gameTimeObjects?.find(timeObj => {
      const lowTime = new Date(timeObj.gameabletime_low);
      const highTime = new Date(timeObj.gameabletime_high);
      return now >= lowTime && now <= highTime;
    });
  };

  useEffect(() => {
    // Initial check
    setCurrentGameTime(findCurrentGameTime());

    // Calculate the minimum update interval (1 second is a reasonable default)
    const UPDATE_INTERVAL = 1000; // 1 second

    // Set up interval to check time ranges
    const intervalId = setInterval(() => {
      const newGameTime = findCurrentGameTime();
      
      // Only update state if the result has changed
      if (JSON.stringify(newGameTime) !== JSON.stringify(currentGameTime)) {
        setCurrentGameTime(newGameTime);
      }
    }, UPDATE_INTERVAL);

    // Cleanup interval on unmount or when gameTimeObjects changes
    return () => clearInterval(intervalId);
  }, [gameTimeObjects]); // Re-run effect if gameTimeObjects changes

  return currentGameTime;
};
































const SnookerBall = ({ number, color, selected, onClick }) => {
  const winningMult = calculateWinningMultiplier('x', number);
  const ballColor = {
    green: require('./balls-img/green.png'),
    red: require('./balls-img/red.png'),
    redviolet: require('./balls-img/red.png'),
    greenviolet: require('./balls-img/green.png')
  };

  const showVioletOverlay = number === 0 || number === 5;

  return (
    <Box
      sx={{
        position: 'relative',
        width: 60,
        height: 60,
        borderRadius: '50%',
        overflow: 'hidden',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <img 
        style={{ width: 120 }} 
        src={ballColor?.[winningMult?.['winningColorMashed']]} 
      />

      {/* Violet Gradient Overlay */}
      {showVioletOverlay && (
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '50%',
            height: '100%',
            background: 'linear-gradient(to right, rgba(138, 43, 226, 1), rgba(147, 112, 219, 0.6))',
            zIndex: 1,
          }}
        />
      )}

      {/* Centered Number */}
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 30,
          height: 30,
          backgroundColor: 'white',
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          zIndex: 2,
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          {number}
        </Typography>
      </Box>
    </Box>
  );
};




// Previous results display
const PreviousResults = ({ results }) => (
  <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
    {results.map((result, index) => (
      <Box
        key={index}
        sx={{
          width: 40,
          height: 40,
          borderRadius: '50%',
          bgcolor: result.color === 'green' ? '#4CAF50' : 
                   result.color === 'red' ? '#f44336' : '#9C27B0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'white',
          fontSize: '1.2rem',
          fontWeight: 'bold',
          border: '2px solid rgba(255,255,255,0.3)',
        }}
      >
        {result.number}
      </Box>
    ))}
  </Box>
);

const PredictionGame = () => {
  const [timeOption, setTimeOption] = useState(supportedGamesEngines()[0]);
  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [multiplier, setMultiplier] = useState('X1');
  const [allData, setAllData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [betValue, setBetValue] = useState('');
  const [endMenuText, setEndMenuText] = useState('Results');
  
  const dispatch = useDispatch()

  const allGames = useSelector(getAllGameSelect);

  const loadSystemGames = (seconds = null) => {
    setLoading(true)
    dispatch(gameActions.loadAllGames(`trade/allblock/${seconds ?? timeOption?.seconds}`, response => {
      setLoading(false)
      // notify(response.msg, response.code)

    }))
  }

  const loadGamesHistory = (seconds = null) => {

    dispatch(gameActions.loadPlayedHistory(`game/history/${seconds ?? timeOption?.seconds}`, response => {

    }))
  }

  const loadUserHistory = (seconds = null) => {

    dispatch(gameActions.loadUserGames(`game/user/history`, response => {
console.log('@@@@@@@@@@@@@@ once man')
    }))
  }


  

  useEffect(() => {
    loadSystemGames()
    loadGamesHistory()
    loadUserHistory()
  }, [timeOption])

  const colors = [
    { name: 'Green', style: {borderTopLeftRadius: 0, borderBottomRightRadius: 0}, value: '#4CAF50' },
    { name: 'violet', value: '#9400D3' },
    { name: 'Red', style: {borderTopRightRadius: 0, borderBottomLeftRadius: 0}, value: '#f44336' }
  ];
  const numbers = Array.from({ length: 10 }, (_, i) => i);

  const sizePlay = [
    {name: 'BIG', style: {borderTopLeftRadius: 20, borderBottomLeftRadius: 20}, bgColor: '#ff9800', hoverColor: '#f57c00'},
    {name: 'SMALL', style: {borderTopRightRadius: 20, borderBottomRightRadius: 20}, bgColor: '#2196f3', hoverColor: '#1976d2'}
  ]

  
  const onBetNow = (value) => {
    setBetValue(value)
    setOpen(true)
  }


  const closeGameModal = () => {
    loadUserHistory()
    loadGamesHistory()
    setOpen(false)
  }













  
  const currentGameBand = useCurrentGameTime(allGames);
  const timeDifference = useTimeDifference(currentGameBand, {readOnly: false}, closeGameModal)



  return (
    <SiteWide title={"Home"}>
        <Section overlayText={'Loading...'} loading={loading} title={'Game'}>
          <Container style={{paddingTop: 15, paddingBottom: 45}}>
          
            {/* Time Options */}
            <Box sx={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                mb: 2,
                gap: 1,
            }}>
                {supportedGamesEngines()?.map((option) => {

                  const isSelected = timeOption?.seconds === option?.seconds

                  return <Paper
                      key={option?.seconds}
                      elevation={isSelected ? 8 : 2}
                      sx={{
                      flex: 1,
                      p: 1.5,
                      textAlign: 'center',
                      cursor: 'pointer',
                      bgcolor: isSelected ? '#3f51b5' : '#1c222b',
                      color: isSelected ? 'white' : 'text.secondary',
                      transition: 'all 0.3s',
                      '&:hover': {
                          bgcolor:isSelected ? '#3f51b5' : '#172636',
                      }
                      }}
                      onClick={() => setTimeOption(option)}
                  >
                      <img
                        style={{
                            width: 40,
                            filter: !isSelected ? 'grayscale(100%)' : 'none',
                        }}
                        src={require('./balls-img/medal.png')}
                        alt="Medal"
                      />

                      <Typography style={{color: '#fff'}} variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                          Win Go
                      </Typography>
                      <Typography style={{color: '#fff'}} variant="h6">
                          {option?.name} {option?.titleShort}
                      </Typography>
                  </Paper>

              })}
            </Box>

            {/* Game Info Panel */}
            <TicketComponent
                 ticketId={`${currentGameBand?.hash}${currentGameBand?.txn_id}`} minute={`${timeOption?.name} ${timeOption?.titleShort}`} 
                 timeLeft={timeDifference}
            />

            {/* Color Selection */}
            <Box sx={{ 
                display: 'flex', 
                gap: 2,
                mb: 3,
            }}>
                {colors.map(({ name, value, style }) => (
                  <Button
                    style={{borderRadius: 15, ...style}}
                    key={name}
                    fullWidth
                    variant="contained"
                    sx={{
                    py: 2,
                    bgcolor: value,
                    color: 'white',
                    borderRadius: 2,
                    '&:hover': {
                        bgcolor: value,
                        opacity: 0.9,
                    },
                    border: selectedColor === name ? '2px solid white' : 'none',
                    boxShadow: selectedColor === name ? '0 0 15px rgba(255,255,255,0.3)' : 'none',
                    }}
                    onClick={() => onBetNow(name?.toLowerCase())}
                  >
                    {name}
                  </Button>
                ))}
            </Box>

            {/* Number Selection */}
            <Box sx={{ 
                display: 'grid',
                gridTemplateColumns: 'repeat(5, 1fr)',
                gap: 2,
                mb: 3,
                p: 2,
                bgcolor: 'rgba(255,255,255,0.01)',
                borderRadius: 2,
            }}>
                {numbers.map((number) => (
                <SnookerBall
                    key={number}
                    number={number}
                    color={number % 2 === 0 ? '#f44336' : '#4CAF50'}
                    selected={selectedNumber === number}
                    onClick={() => onBetNow(number)}

                />
                ))}
            </Box>

              
            <Paper
                elevation={0}
                sx={{
                display: 'flex',
                mb: 3,
                overflow: 'hidden',
                borderRadius: 2,
                }}
            >

              {sizePlay?.map(item => {
                
                return <Button
                  onClick={() => onBetNow(item?.name?.toLowerCase())}
                  fullWidth
                  sx={{
                      fontSize: '1.25rem',
                      bgcolor: item?.bgColor,
                      color: 'white',
                      borderRadius: 0,
                      '&:hover': {
                      bgcolor: item?.hover,
                      },
                      border: selectedSize?.name == item?.name ? '2px solid white' : 'none',

                  }}
                  style={item.style}
                  >
                    {item?.name}
                  </Button>}
                  
              )}

            </Paper>


            

            {/* Bottom Navigation */}
            <Box sx={{ 
                display: 'flex',
                gap: 2,
            }}>
                {[
                { text: 'Results', icon: <HistoryIcon />, active: true },
                // { text: 'Chart', icon: <TimelineIcon />, active: false },
                { text: 'Played', icon: <PersonIcon />, active: false }
                ].map(({ text, icon }) => {
                  
                  const active = endMenuText == text

                  return <Button
                      key={text}
                      startIcon={icon}
                      variant={active ? 'contained' : 'outlined'}
                      sx={{
                      flex: 1,
                      // py: 1.5,
                      color: active ? 'white' : '#000',
                      bgcolor: active ? '#3f51b5' : '#fff',
                      borderColor: '#3f51b5',
                      '&:hover': {
                          bgcolor: active ? '#3f51b5' : 'rgba(255,255,255,0.1)',
                      },
                      }}
                      onClick={el => setEndMenuText(text)}
                  >
                    {text}
                  </Button>

              })}
            </Box>
            
            <Box sx={{ 
                display: 'flex',
                gap: 2,
                mt: 2
            }}>
              {endMenuText == 'Results' && <ResultTable />}
              {endMenuText == 'Played' && <UserHistory />}
            </Box>







          </Container>
          <BettingModal currentGameBand={currentGameBand} betValue={betValue} timeOption={timeOption} open={open} onClose={closeGameModal} />
        </Section>
    </SiteWide>
  );
};

export default PredictionGame;
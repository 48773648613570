import React, { useState } from 'react';
import {
  Box,
  Backdrop,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
} from '@mui/material';
import {
  ContactSupport,
  HeadsetMic,
  WhatsApp,
  LocationOn,
  Close,
} from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

const AnimatedFAB = () => {
  const [open, setOpen] = useState(false);
    const history = useHistory()
  const actions = [
    { icon: <ContactSupport />, name: 'Contact Us', onClick: () => history.push('/contact') },
    { icon: <HeadsetMic />, name: 'Customer Care', onClick: () => window.location.href = 'https://tawk.to/chat/62fa1f2654f06e12d88eb185/1gaggjcgu'},
  ];

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={{ position: 'relative', mt: 3, height: 320 }}>
      <Backdrop open={open} />
      <SpeedDial
        ariaLabel="Navigation SpeedDial"
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
        icon={<SpeedDialIcon icon={<HeadsetMic />} openIcon={<Close />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={open}
        FabProps={{
          sx: {
            bgcolor: 'primary.main',
            '&:hover': {
              bgcolor: 'primary.dark',
            }
          }
        }}
      >
        {actions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => {
              action.onClick();
              handleClose();
            }}
            sx={{
              '& .MuiSpeedDialAction-fab': {
                bgcolor: 'secondary.main',
                '&:hover': {
                  bgcolor: 'secondary.dark',
                }
              }
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};

export default AnimatedFAB;
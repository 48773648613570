import React, { useState } from 'react';
import { 
  Paper, Table, TableBody, TableCell, Tooltip, TableContainer, 
  TableHead, TableRow, Box, TablePagination, useTheme, 
  Typography, Chip, Skeleton, Alert
} from '@mui/material';
import { useSelector } from 'react-redux';
import { getAllGameSelect, getUserGameSelect } from '../../redux/selectors';
import { calculateWinningMultiplier, money } from '../../utility/Helper1';
import { TrendingUp, TrendingDown } from '@mui/icons-material';

const UserHistory = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const theme = useTheme();
  const data = useSelector(getUserGameSelect);

  const getGradientStyle = (colorsArray) => ({
    background: `linear-gradient(45deg, ${colorsArray?.[0] ?? '#e0e0e0'}, ${colorsArray?.[1] ?? colorsArray?.[0] ?? '#e0e0e0'})`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    fontWeight: 700,
    fontSize: '1.1rem',
    display: 'inline-block',
    padding: '4px 8px',
    borderRadius: theme.shape.borderRadius,
    filter: 'drop-shadow(1px 1px 1px rgba(0,0,0,0.2))'
  });

  const getColorDots = (colorArray) => (
    <Box sx={{ display: 'flex', gap: 0.8, alignItems: 'center' }}>
      {colorArray?.map((color, index) => (
        <Box
          key={index}
          sx={{
            width: 12,
            height: 12,
            borderRadius: '50%',
            backgroundColor: color,
            border: `2px solid ${theme.palette.background.paper}`,
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
          }}
        />
      ))}
    </Box>
  );

  const interpreteUserGame = (userGame) => {
    const categories = {
      color: ['green', 'red', 'violet'],
      size: ['big', 'small']
    };
    
    return categories.color.includes(userGame) ? 'color' : 
           categories.size.includes(userGame) ? 'size' : 'number';
  };

  const renderResult = (row, bigOrSmall, colorsArray, interprete) => {
    switch(interprete) {
      case 'number':
        return <Box sx={getGradientStyle(colorsArray)}>{row.winning_number}</Box>;
      case 'size':
        return <Chip 
          label={bigOrSmall?.['winningSize'][0]} 
          size="small" 
          color={bigOrSmall?.['winningSize'][0] === 'big' ? 'primary' : 'secondary'}
        />;
      case 'color':
        return getColorDots(colorsArray);
      default:
        return null;
    }
  };

  const renderVerdict = (row) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {Number(row.loseOrWin) == 0 ? (
        <Chip 
          icon={<TrendingDown />} 
          label="Lost" 
          color="error" 
          size="small" 
          variant="outlined"
        />
      ) : (
        <Chip 
          icon={<TrendingUp />} 
          label={money(row.walkAwayAmount, row.currency)} 
          color="success" 
          size="small"
        />
      )}
    </Box>
  );

  if (!data?.length) {
    return <Alert severity="info">No history available</Alert>;
  }

  return (
    <Paper 
      elevation={3}
      sx={{ 
        width: '100%',
        borderRadius: 2,
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper
      }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: theme.palette.primary.main }}>
              {['Period', 'Game Played', 'Capital', 'Result', 'Verdict'].map((header) => (
                <TableCell 
                  key={header}
                  sx={{ 
                    color: 'white', 
                    fontWeight: 600,
                    padding: '12px 16px',
                    whiteSpace: 'nowrap'
                  }}
                >
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row) => {
                const bigOrSmall = calculateWinningMultiplier(row.winning_number, row.winning_number);
                const colorsArray = bigOrSmall?.['winningColor'] ?? [];
                const newTxnId = `${row.hash}${row.txn_id}`;
                const playedComb = calculateWinningMultiplier(row.gamePlayed);
                const interprete = interpreteUserGame(row.gamePlayed);
                
                const getWinning = calculateWinningMultiplier(row.gamePlayed, row.winning_number);

                return (
                  <TableRow 
                    key={row.hash}
                    sx={{ 
                      '&:hover': { 
                        backgroundColor: theme.palette.action.hover,
                        transition: 'background-color 0.2s'
                      },
                      '&:nth-of-type(odd)': {
                        backgroundColor: theme.palette.action.hover
                      }
                    }}
                  >
                    <TableCell>
                      <Tooltip title={newTxnId} arrow>
                        <Typography variant="body2" sx={{ fontFamily: 'monospace', cursor: 'pointer' }}>
                          {`${newTxnId?.slice(0, 5)}...${newTxnId?.slice(-5)}`}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <Box sx={getGradientStyle(playedComb?.['winningColors'] ?? [])}>
                        {row.gamePlayed}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" sx={{ fontWeight: 500 }}>
                        {money(row.capital, row.currency)} {}
                      </Typography>
                    </TableCell>

                    <TableCell>
                      {row.winning_number != 'XXX' && renderResult(row, bigOrSmall, colorsArray, interprete)}
                      {/* {row?.awarded == 1 && renderResult(row, bigOrSmall, colorsArray, interprete)} */}
                    </TableCell>
                    <TableCell>
                      {row.winning_number != 'XXX' && renderVerdict(Number(row?.awarded) == 1 ? row : {
                                      loseOrWin: Number(getWinning?.multiplier) == 0?0:1, 
                                      walkAwayAmount: Number(getWinning?.multiplier) * Number(row.capital), 
                                      currency: row.currency
                                    })}
                      {/* {row?.awarded == 1 && renderVerdict(row)} */}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50]}
        component="div"
        count={data?.length ?? 0}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={(_, newPage) => setPage(newPage)}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(0);
        }}
        sx={{
          borderTop: `1px solid ${theme.palette.divider}`,
          backgroundColor: theme.palette.background.default
        }}
      />
    </Paper>
  );
};

export default UserHistory;
import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  Typography,
  IconButton,
  InputAdornment,
  Alert,
  CircularProgress
} from '@mui/material';
import { Visibility, VisibilityOff, LockReset, Email } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import APIRequests from '../utility/APIRequests';
import { notify } from '../utility/Helper1';

const ForgetPassword = () => {
  const [formData, setFormData] = useState({
    email: '',
    newPassword: '',
    confirmPassword: '',
    emailCode: ''
  });
  
  const [uiLevel, setUiLevel] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [expiryTime, setExpiryTime] = useState(null);
  
  const history = useHistory();

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
    setError('');
  };

  const requestOTP = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const api = new APIRequests();
      const response = await api.runPost("account/reset_password_pin", {
        email: formData.email
      });

      if (response?.code === 1) {
        setUiLevel(1);
        setExpiryTime(new Date(response.data.passwordResetCodeTimer));
        notify(response.msg, response.code);
      } else {
        setError(response.msg);
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const changePassword = async (e) => {
    e.preventDefault();
    
    if (formData.newPassword !== formData.confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!formData.newPassword) {
      setError('Please enter a new password');
      return;
    }

    setLoading(true);
    
    try {
      const api = new APIRequests();
      const response = await api.runPost("account/reset_password", {
        email: formData.email,
        password: formData.newPassword,
        passwordResetCode: formData.emailCode
      });

      if (response?.code === 1) {
        notify("Password reset successful. Redirecting to login page...", 1);
        setTimeout(() => history.replace('/signin'), 3000);
      } else {
        setError(response.msg);
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const TimeRemaining = ({ expiryTime }) => {
    const [timeLeft, setTimeLeft] = useState('');

    React.useEffect(() => {
      const timer = setInterval(() => {
        const now = new Date().getTime();
        const distance = new Date(expiryTime).getTime() - now;
        
        if (distance < 0) {
          setTimeLeft('Expired. Please try again');
          clearInterval(timer);
        } else {
          const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((distance % (1000 * 60)) / 1000);
          setTimeLeft(`Expires in ${minutes}:${seconds < 10 ? '0' : ''}${seconds}`);
        }
      }, 1000);

      return () => clearInterval(timer);
    }, [expiryTime]);

    return (
      <Typography 
        variant="body2" 
        color={timeLeft.includes('Expired') ? 'error' : 'primary'}
        sx={{ mt: 2 }}
      >
        {timeLeft}
      </Typography>
    );
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // background: 'linear-gradient(135deg, #667eea 0%, #764ba2 100%)',
        background: 'linear-gradient(135deg, #202630 0%, #171e26 100%)',
        position: 'fixed',
        top: 0,
        left: 0,
        overflow: 'auto',
        padding: { xs: 2, sm: 4 }
      }}
    >
      <Card
        elevation={8}
        sx={{
          width: '100%',
          maxWidth: '440px',
          borderRadius: 2,
          backdropFilter: 'blur(20px)',
          backgroundColor: 'rgba(255, 255, 255, 0.95)',
          position: 'relative',
          my: 'auto'
        }}
      >
        <CardContent sx={{ p: { xs: 3, sm: 4 } }}>
          <Box sx={{ textAlign: 'center', mb: 4 }}>
            <Typography
              variant="h4"
              component="h1"
              sx={{ 
                fontWeight: 'bold',
                color: '#1a237e',
                mb: 1
              }}
            >
              {uiLevel === 0 ? 'Reset Password' : 'Create New Password'}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {uiLevel === 0 ? 'Enter your email to reset password' : 'Enter your new password and verification code'}
            </Typography>
          </Box>

          {error && (
            <Alert 
              severity="error" 
              sx={{ mb: 3 }}
            >
              {error}
            </Alert>
          )}

          <form onSubmit={uiLevel === 0 ? requestOTP : changePassword}>
            {uiLevel === 0 ? (
              <TextField
                fullWidth
                label="Email Address"
                variant="outlined"
                type="email"
                value={formData.email}
                onChange={handleChange('email')}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Email />
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 3 }}
              />
            ) : (
              <>
                <TextField
                  fullWidth
                  label="New Password"
                  variant="outlined"
                  type={showPassword ? 'text' : 'password'}
                  value={formData.newPassword}
                  onChange={handleChange('newPassword')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 3 }}
                />

                <TextField
                  fullWidth
                  label="Confirm Password"
                  variant="outlined"
                  type={showConfirmPassword ? 'text' : 'password'}
                  value={formData.confirmPassword}
                  onChange={handleChange('confirmPassword')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                          edge="end"
                        >
                          {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{ mb: 3 }}
                />

                <TextField
                  fullWidth
                  label="Verification Code"
                  variant="outlined"
                  value={formData.emailCode}
                  onChange={handleChange('emailCode')}
                  sx={{ mb: 3 }}
                />

                {expiryTime && <TimeRemaining expiryTime={expiryTime} />}
              </>
            )}

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={loading}
              sx={{
                py: 1.5,
                bgcolor: '#1a237e',
                '&:hover': {
                  bgcolor: '#283593'
                },
                mb: 3
              }}
              startIcon={loading ? <CircularProgress size={20} /> : <LockReset />}
            >
              {loading ? 'Processing...' : (uiLevel === 0 ? 'Request Reset' : 'Reset Password')}
            </Button>
          </form>

          <Box sx={{ textAlign: 'center' }}>
            <Typography variant="body2" color="textSecondary">
              Remember your password?{' '}
              <Button
                color="primary"
                onClick={() => history.push('/signin')}
                sx={{ textTransform: 'none' }}
              >
                Sign in
              </Button>
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ForgetPassword;
import React, { useState } from 'react';
import {
  Modal,
  Box,
  Typography,
  Stack,
  TextField,
  Button,
  IconButton,
  Slider,
  FormControl,
  InputAdornment,
  Alert
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { calculateWinningMultiplier, money, notify } from '../../utility/Helper1';
import { useDispatch, useSelector } from 'react-redux';
import { gameActions } from '../../redux/actions';
import { getUserHistorySelect, homeScreenStatSelect } from '../../redux/selectors';
import { useTimeDifference } from './useTimeDifference';

const BettingModal = ({ betValue, timeOption, currentGameBand, open, onClose }) => {

  const finance = useSelector(homeScreenStatSelect);
  const [betAmount, setBetAmount] = useState(10);
  const [odds, setOdds] = useState(1);
  const [multiplier, setMultiplier] = useState(5);
  const [submitting, setSubmitting] = useState(false);
  const [currency, setCurrency] = useState(finance?.[0]);

  const calculatedWinning = calculateWinningMultiplier(betValue)
  const potentialWinnings = (betAmount * odds).toFixed(2);
  const dispatch = useDispatch()

  const packages = {170: [5, 10, 20, 50, 100, 200], 175: [1000, 5000, 10000, 50000, 100000, 200000]};
  const bgRaw = calculatedWinning?.winningColors
  const backgroundColor = `linear-gradient(135deg, ${bgRaw?.[0]}, ${bgRaw?.[1] ?? bgRaw?.[0]})`


  const capital = odds * multiplier


  const betNow = () => {
    setSubmitting(true)
    dispatch(gameActions.onBetNewGame({
                                      capital, 
                                      gamePlayed: betValue,
                                      seconds: timeOption?.seconds, 
                                      id: currentGameBand?.id, 
                                      currency: currency?.type
                                    }, `game/new`, response => {
      setSubmitting(false)
      notify(response.msg, response.code)

      if(response.code == 1){
        onClose()
      }
      
    }))
  }



const CurrencyOption = ({ isActive, currency, icon, onClick }) => (
  <Box
    onClick={onClick}
    sx={{
      cursor: 'pointer',
      position: 'relative',
      opacity: isActive ? 1 : 0.5,
      transition: 'all 0.2s ease',
      '&:hover': {
        opacity: 0.8
      }
    }}
  >
    <Stack 
      direction="row" 
      spacing={1} 
      alignItems="center"
      sx={{
        color: isActive ? 'text.primary' : 'text.secondary',
      }}
    >
      <Box
        component="img"
        src={icon}
        sx={{
          width: 24,
          height: 24,
          filter: isActive ? 'none' : 'grayscale(100%)'
        }}
      />
      <Typography
        sx={{
          fontWeight: isActive ? 600 : 400,
        }}
      >
        {currency}
      </Typography>
    </Stack>
    {isActive && (
      <Box
        sx={{
          position: 'absolute',
          bottom: -4,
          left: 0,
          right: 0,
          height: 2,
          background: (theme) => theme.palette.primary.main,
          borderRadius: 1
        }}
      />
    )}
  </Box>
);



  const balance = finance?.find(el => el.type == currency.type)
  const timeRemaining = useTimeDifference(currentGameBand, {readOnly: true})

  return (
    <Modal
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}
      open={open}
      onClose={onClose}
      aria-labelledby="betting-modal"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          borderRadius: 2,
          boxShadow: 24,
          bgcolor: 'background.paper',
          overflow: 'hidden', // Ensures the shape stays inside the modal
        }}
      >
        {/* V-Shaped Background */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 180,
            width: '100%',
            height: '25%', // Covers 1/4 of the total height
            background: backgroundColor,
            clipPath: 'polygon(0% 0%, 50% 100%, 100% 0%)', // V-shape
            zIndex: 0,
          }}
        />

        {/* Modal Content */}
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
            p: 4,
          }}
        >
          <IconButton
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center', 
            mb: 3 
          }}>
            <Typography variant="h5" component="h2">
              Selection: <span style={{fontWeight: 900, color: bgRaw?.[0]}}>
                {String(betValue)?.toUpperCase()}
              </span>
            </Typography>
          </Box>
          <Stack direction="row" spacing={3}>
              {finance?.map((curr) => (
                <CurrencyOption
                  key={curr.abbr}
                  isActive={currency?.abbr == curr?.abbr}
                  currency={curr.name}
                  icon={curr.image}
                  onClick={() => {setCurrency(curr); setMultiplier(null)}}
                />
              ))}
            </Stack>

          <Alert style={{backgroundColor: 'transparent', padding: 0}} severity="info" sx={{ mt: 3, mb: 3, mx: 0 }}>
            Current Balance: {money(balance?.balance ?? 0, balance?.type)}
          </Alert>

          <form>
            <FormControl fullWidth sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                  mb: 3,
                }}
              >
                {packages?.[currency?.type]?.map((value) => (
                  <Button
                    key={value}
                    variant={multiplier === value ? 'contained' : 'outlined'}
                    sx={{
                      flex: value === 'Random' ? '100%' : 1,
                      px: 0,
                      color: multiplier === value ? 'white' : '#000',
                      // bgcolor: multiplier === value ? backgroundColor : 'transparent',
                      borderColor: '#eee',
                      '&:hover': {
                        bgcolor: multiplier === value ? backgroundColor : 'rgba(255,255,255,0.1)',
                      },
                    }}
                    style={{background: multiplier === value ? backgroundColor : 'transparent',}}
                    onClick={() => {
                      setMultiplier(value);
                    }}
                  >
                    {value}
                  </Button>
                ))}
              </Box>

              <Typography gutterBottom>Set multiplier</Typography>
              <Slider
                value={odds}
                onChange={(_, value) => setOdds(value)}
                min={1}
                max={100}
                step={1}
                marks={[
                  { value: 1, label: '1x' },
                  { value: 100, label: '100x' },
                ]}
                sx={{ mb: 3 }}
                valueLabelDisplay="on"
              />

              <TextField
                style={{ marginTop: 16 }}
                label="Bet Amount"
                type="number"
                disabled={true}
                value={capital}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
              />
            </FormControl>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                mb: 1,
                p: 2,
                bgcolor: 'grey.100',
                borderRadius: 1,
              }}
            >
              {/* <Typography>Potential Winnings:</Typography>
              <Typography variant="h6" color="primary">
                {money((calculatedWinning?.multiplier * capital), currency?.type)}
              </Typography> */}

              <Typography>Time Left:</Typography>
              <Typography variant="h6" color="primary">
                {timeRemaining}
              </Typography>


            </Box>

            {/* <Typography variant="body2" color="textSecondary" sx={{ mb: 1, fontSize: 10 }}>
              Note: Potential winnings may vary based on the result. For example, selecting "Green" and the winning number
              being 5 yields 1.5x instead of 2x.
            </Typography> */}

              {/* <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                {timeRemaining}
              </div> */}

            <Button
              variant="contained"
              fullWidth
              size="large"
              sx={{
                mt: 2,
                textTransform: 'none',
                fontSize: '1.1rem',
                background: backgroundColor
              }}
              // style={{background: multiplier === value ? backgroundColor : 'transparent',}}
              onClick={betNow}
              disabled={submitting}
            >
              Place Bet
            </Button>
          </form>
        </Box>
      </Box>
    </Modal>

  );
};

export default BettingModal;
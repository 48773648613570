import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../redux/actions';
import { userSelect, allRemoteSettingsSelect, homeScreenStatSelect } from '../redux/selectors/index';
import { notify, money } from '../utility/Helper1';
import { mainColor, otherColors } from '../utility/styles';
import {WebLink, SiteWide} from "./customComponents";

import Section from "./layouts/Section";

// Material UI imports
import { 
  Container, 
  Typography, 
  Box, 
  Paper, 
  Divider, 
  Grid, 
  Button, 
  IconButton,
  Menu,
  MenuItem,
  Card,
  CardContent,
  CardActions, 
  CircularProgress,
  Backdrop
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';

// Custom styled components
const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: otherColors.cotpBlue,
  borderRadius: theme.spacing(2),
  marginBottom: theme.spacing(3),
  color: '#fff'
}));

const SalaryButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#fff',
  color: otherColors.cotpBlue,
  padding: theme.spacing(1.5),
  fontWeight: 'bold',
  '&:hover': {
    backgroundColor: '#f0f0f0'
  }
}));

function Salary() {
  const [loadingText, setLoadingText] = useState('Updating...');
  const [loading, setLoading] = useState(false);
  const [mySalaryData, setMySalaryData] = useState({});
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const site_settings = useSelector(allRemoteSettingsSelect);
  const finance = useSelector(homeScreenStatSelect);
  const userData = useSelector(userSelect);
  const salaryData = site_settings.salary_settings;
  
  const dispatch = useDispatch();

  const handleMenuOpen = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const loadSalary = (myrefid, level) => {
    setLoading(true);

    dispatch(authActions.onRefreshSystemData(`salary`, 10, {}, response => {
      setLoading(false);
      if (response.code == 1) {
        setMySalaryData(response?.data?.mySalary);
      }
    }));
  };
  
  useEffect(() => {
    loadSalary(userData?.myrefid, 1);
  }, []);

  const requestSalary = () => {
    handleMenuClose();
    setLoading(true);
    setLoadingText('Requesting salary...');

    dispatch(authActions.onRefreshSystemData(`salary/request`, 10, {}, response => {
      notify(response.msg, response.code);
      if (response.code == 1) {
        // Handle success
      }
      setLoading(false);
    }));
  };

  const processSalaryWithdrawal = () => {
    setLoading(true);
    setLoadingText('Processing withdrawal...');

    dispatch(authActions.onLogin(`withdraw/salary`, {}, response => {
      notify(response.msg, response.code);
      if (response.code == 1) {
        // Handle success
      }
      setLoading(false);
    }));
  };

  return (<SiteWide title="Earn salary">
        <Section overlayText={loadingText} loading={loading} title={'Earn salary'}>
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Backdrop open={loading} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <CircularProgress color="inherit" />
          <Typography variant="body1" sx={{ mt: 2 }}>{loadingText}</Typography>
        </Box>
      </Backdrop>
      
      
      
      <StyledCard elevation={3}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6" component="div">
              {salaryData?.salaryHeadline || 'Salary Program'}
            </Typography>
            <IconButton 
              aria-label="more options" 
              onClick={handleMenuOpen}
              sx={{ color: '#fff' }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={requestSalary}>Request salary</MenuItem>
            </Menu>
          </Box>
          
          <Divider sx={{ my: 2, backgroundColor: 'rgba(255,255,255,0.2)' }} />
          
          <Typography variant="body1" component="div">
            {salaryData?.salaryText || 'Participate in our salary program to earn regular income.'}
          </Typography>
        </CardContent>
      </StyledCard>
      
      <StyledCard elevation={3}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                <AttachMoneyIcon sx={{ mr: 1 }} />
                Remitted Salary
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" component="div" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                {money(finance?.filter((item) => item.type == salaryData?.salaryType)?.[0]?.salary ?? 0, salaryData?.salaryType ?? 0)}
              </Typography>
            </Grid>
            
            <Grid item xs={12}>
              <Divider sx={{ my: 2, backgroundColor: 'rgba(255,255,255,0.2)' }} />
            </Grid>
            
            <Grid item xs={6}>
              <Typography variant="subtitle1" component="div" sx={{ display: 'flex', alignItems: 'center' }}>
                <PersonOutlineIcon sx={{ mr: 1 }} />
                Total active downline
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="subtitle1" component="div" sx={{ textAlign: 'right', fontWeight: 'bold' }}>
                {mySalaryData?.activedownlines || 0}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        
        <CardActions sx={{ p: 2 }}>
          <SalaryButton 
            variant="contained" 
            fullWidth 
            onClick={processSalaryWithdrawal}
            startIcon={<AttachMoneyIcon />}
            size="large"
          >
            Claim Remitted Salary
          </SalaryButton>
        </CardActions>
      </StyledCard>
    </Container>
    </Section>
    </SiteWide>
  );
}

export default Salary;